import { useGeo, useShippingAddress } from "@simple/contexts";
import { useCountries } from "@simple/contexts";
import { useShoppingCart } from "@simple/contexts";
import { useStores } from "@simple/contexts";
import { usePostTalkShop } from "@simple/services";
import { DEFAULT_VENDOR, getShoppingCartProductsByVendor } from "@simple/utils";
import { formatByCurrency } from "artisn-ui-react";
import { validateShoppingCart } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";

import { getCartMessage } from "./CartPayButton.helpers";
import Styles from "./CartPayButton.styles";
import { CartPayButtonProps as Props } from "./CartPayButton.types";
import { currencyOptionsDefault } from "components/categories/FiltersAccordion/FilterAccordion.helpers";
import useShoppingCartCalculations from "components/checkout/Checkout/hooks/useShoppingCartCalculation";
import Button from "components/global/Button/Button";
import ShoppingCartNotifications from "components/global/notifications/ShoppingCartNotifications/ShoppingCartNotifications";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useI18n from "hooks/useI18n";
import { defaultFunction, shouldMock } from "utils/common.utils";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { ARTISN } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { PHONE_NUMBER, RETURN_TO_APP_URL } = CONSTANTS.INTEGRATIONS.WHATSAPP;
const { WITH_PURCHASE, WITH_DELIVERY } = CONSTANTS.FEATURE_FLAGS;

const CartPayButton: React.FC<Props> = props => {
  const { empty, setDisabled = defaultFunction } = props;
  const { showModifiers, shoppingCart } = useShoppingCart();
  const { setErrors = defaultFunction, className, talkShop } = props;
  const { selectedCoordinates } = useGeo();
  const { selectedShippingAddress } = useShippingAddress();

  const { uid, isAnonymous } = useAuth();
  const [showStoreNotification, setShowStoreNotification] = useState(false);
  const [showNoCoverage, setShowNoCoverage] = useState(false);
  const [showMapCoordinates, setShowMapCoordinates] = useState(false);
  const router = useRouter();
  const { mutate, isLoading } = usePostTalkShop(uid);
  const cartProducts = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProductsByVendor(shoppingCart, DEFAULT_VENDOR.id);
  }, [shoppingCart]);
  const { talkShopIdentifier } = useTalkShop();
  const { selectedCountry } = useCountries();
  const { currency } = selectedCountry;
  const { selectedStore } = useStores();

  const { minOrderAmount = 0, maxOrderAmount = 0 } = selectedStore ?? {};
  const { totalToPay: total } = useShoppingCartCalculations();
  const t = useI18n();

  const payButtonHandler = async () => {
    if (minOrderAmount > 0 && minOrderAmount > total) {
      dismissErrorNotification();
      createErrorNotification(
        `Agrega al menos $${minOrderAmount} para realizar tu pedido`,
        "Tu pedido no cumple con el monto mínimo"
      );
      return;
    }
    if (!selectedShippingAddress) {
      dismissErrorNotification();
      createErrorNotification(
        `Debe seleccionar una dirección de envío para poder hacer tu pedido`
      );
      return;
    }

    if (maxOrderAmount > 0 && maxOrderAmount < total) {
      dismissErrorNotification();
      createErrorNotification(
        `Agrega artículos hasta un valor de $${maxOrderAmount} para poder hacer tu pedido`,
        "Tu pedido excede el monto máximo"
      );
      return;
    }

    if (!selectedCoordinates) {
      setShowMapCoordinates(true);
      return;
    }
    if (!shoppingCart || !uid) return;
    if (!Object.keys(shoppingCart.stores).length) return;
    const { lat, lng } = selectedCoordinates;
    const validate = !shouldMock ? validateShoppingCart : () => undefined;

    const errors = await validate({
      latitude: lat,
      longitude: lng,
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous
    });
    const { products, stores } = errors ?? {};
    stores?.forEach(store => {
      const { type } = store;
      if (type === "IS_OPEN" && stores.length === 1) {
        setShowStoreNotification(true);
      }
      if (type === "OUT_OF_COVERAGE" && WITH_DELIVERY) {
        setShowNoCoverage(true);
      }
    });
    const validationErrors = stores?.filter(store => {
      if (!WITH_DELIVERY) {
        return !(store.type === "OUT_OF_COVERAGE");
      }
      return store;
    });
    const hasErrors = !!products?.length || !!validationErrors?.length;
    if (hasErrors && !shouldMock) {
      setDisabled(true);
      setErrors(errors);
      return;
    }

    const talkShopData = {
      to: `whatsapp:+${talkShopIdentifier.trim()}`,
      from: `whatsapp:+${PHONE_NUMBER}`,
      body: `${getCartMessage(
        cartProducts,
        showModifiers,
        shoppingCart,
        selectedCountry
      )}`
    };

    if (talkShop) {
      mutate(talkShopData, {
        onSuccess: () => {
          router.push(RETURN_TO_APP_URL);
        }
      });
      return;
    }

    router.push("/checkout?redirect=false");
  };

  return (
    <Styles className={`CartPayButton ${className}`} talkShop={!!talkShop}>
      <Button
        onClick={payButtonHandler}
        disabled={empty || isLoading || !WITH_PURCHASE}
        className="Cart__summary__button"
      >
        {talkShop ? (
          <p className="CartPayButton__amount">{cartProducts?.length}</p>
        ) : null}
        {talkShop ? t.common.seeCart : t.common.goToPay}
        <p className="CartPayButton__total">
          {total
            ? `${formatByCurrency(
                total || 0,
                currencyOptionsDefault(currency, 2)
              )}`
            : " $0.00"}
        </p>
      </Button>
      <ShoppingCartNotifications
        showMapCoordinates={showMapCoordinates}
        showNoCoverage={showNoCoverage}
        showClosedStore={showStoreNotification}
        setShowMapCoordinates={setShowMapCoordinates}
        setShowNoCoverage={setShowNoCoverage}
        setClosedStore={setShowStoreNotification}
      />
    </Styles>
  );
};

CartPayButton.defaultProps = {
  className: ""
};

export default CartPayButton;
