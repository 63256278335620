import { Button, Clickable, Dropdown } from "artisn-ui-react";
import React from "react";

import Styles, { DropdownOverlayStyled } from "./DropdownOptions.styles";
import { DropdownOptionsProps as Props } from "./DropdownOptions.types";

import MoreVerticalSVG from "../../../../public/assets/images/more-vertical.svg";

const DropdownOptions: React.FC<Props> = props => {
  const { className, onEdit, onDelete, onDefault, isFixed = true } = props;

  const overlayNode = (
    <DropdownOverlayStyled className="DropdownOverlay" isFixed={isFixed}>
      {onDefault ? (
        <Button
          className="DropdownOverlay__option"
          type="LINK"
          onClick={onDefault}
        >
          Predeterminado
        </Button>
      ) : null}
      {onEdit ? (
        <Button
          className="DropdownOverlay__option"
          type="LINK"
          onClick={onEdit}
        >
          Editar
        </Button>
      ) : null}
      {onDelete ? (
        <Button
          className="DropdownOverlay__option"
          type="LINK"
          onClick={onDelete}
        >
          Eliminar
        </Button>
      ) : null}
    </DropdownOverlayStyled>
  );

  return (
    <Styles className={`DropdownOptions ${className}`}>
      <Dropdown overlay={overlayNode} target={["click"]} position="right">
        <Clickable onClick={() => {}}>
          <MoreVerticalSVG />
        </Clickable>
      </Dropdown>
    </Styles>
  );
};

DropdownOptions.defaultProps = {
  className: ""
};

export default DropdownOptions;
