import { Modal } from "artisn-ui-react";
import styled from "styled-components";

import { ModalStyledProps as Props } from "./Modal.types";
import CONSTANTS from "config/constants";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const ModalStyled = styled(Modal)<Props>`
  border-radius: 2.4rem;
  width: min(${getMaxWidth()}, 60rem);
  height: ${props => (props.heightModal ? `${props.heightModal}` : "80%")};

  @media (max-width: ${mobile}px) {
    width: ${getMaxWidth()};
    height: ${getMaxHeight()};
  }

  .Modal {
    &__header {
      justify-content: center;
    }

    &__body {
      border-radius: 2.4rem;
      overflow-y: overlay;
    }
  }
`;

export default ModalStyled;
