import styled from "styled-components";

import { HorizontalProductCardStyledProps as Props } from "./HorizontalProductCard.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { tablet } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const HorizontalProductCardStyled = styled.div<Props>`
  font-family: ${primary};

  .HorizontalProductCard {
    &__product-details {
      display: grid;
      grid-template-columns: 10rem auto auto;
      grid-template-areas:
        "image name total"
        "image description price";
      column-gap: 0.8rem;

      @media (max-width: ${tablet}px) {
        grid-template-columns: 10rem auto;
        grid-template-rows: 1fr auto 1fr;
        grid-template-areas:
          "image name"
          "image description"
          "image price";
      }
    }

    &__image {
      grid-area: image;
    }

    &__name {
      grid-area: name;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.8rem;
      color: var(--palette-black-s0-l10);
      align-self: flex-end;
    }

    &__description {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      gap: 0.5rem;
      color: var(--palette-gray-s0-l35);

      span {
        font-size: 1.3rem;
      }
    }

    &__total {
      align-self: flex-end;
      justify-self: flex-end;
      grid-area: total;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.6rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__price {
      align-self: flex-start;
      justify-self: flex-end;
      grid-area: price;

      .ProductBasePriceInfo--normal,
      .ProductBasePriceInfo--points {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        color: var(--palette-gray-s0-l35);
      }

      @media (max-width: ${tablet}px) {
        align-self: flex-start;
        justify-self: flex-start;
      }
    }
  }
`;

export default HorizontalProductCardStyled;
