import React, { createContext, useCallback, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";

import { GlobalsProviderProps as Props } from "./globals.context.types";
import { GlobalsProviderValue } from "./globals.context.types";

// @ts-ignore
export const GlobalsContext = createContext<GlobalsProviderValue>();

const GlobalsProvider: React.FC<Props> = props => {
  const [joinClub, setJoinClub] = useState(false);

  const resetGlobalsContext = useCallback(() => {}, []);

  const value: GlobalsProviderValue = useMemo(() => {
    return {
      joinClub,
      setJoinClub,
      resetGlobalsContext
    };
  }, [joinClub, resetGlobalsContext]);

  return (
    <GlobalsContext.Provider value={value}>
      <ContextDevTool
        context={GlobalsContext}
        id="globals"
        displayName="Globals"
      />
      {props.children}
    </GlobalsContext.Provider>
  );
};

export default GlobalsProvider;
