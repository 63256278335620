import { useCountries } from "@simple/contexts";
import { formatByCurrency } from "artisn-ui-react";
import React from "react";

import { getFormatPointsOption } from "./ProductBasePriceInfo.helpers";
import Styles from "./ProductBasePriceInfo.styles";
import { ProductBasePriceInfoProps as Props } from "./ProductBasePriceInfo.types";
import CONSTANTS from "config/constants";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";
import useI18n from "hooks/useI18n";

const { WITH_LOYALTY } = CONSTANTS.FEATURE_FLAGS;

const ProductBasePriceInfo: React.FC<Props> = props => {
  const { className, productTotals, orderTotal } = props;
  const { showUnits = false, amount, showAmount = false } = props;
  const t = useI18n();

  const { unitNetPrice, unitPoints } = productTotals ?? {};
  const normal = orderTotal ? orderTotal : unitNetPrice;

  const { selectedCountry } = useCountries();
  const defaultFormatter = useDefaultCurrencyFormatter();

  if (unitNetPrice === 0) {
    return <></>;
  }

  return (
    <Styles className={`ProductBasePriceInfo ${className}`}>
      <p className="ProductBasePriceInfo__dollars">
        {showAmount && amount ? `${amount} X ` : null}
        {formatByCurrency(normal ?? 0, defaultFormatter)}
        {showUnits ? t.common.units : null}
      </p>
      {WITH_LOYALTY && unitPoints && !isNaN(unitPoints) ? (
        <>
          <div className="ProductBasePriceInfo__point" />
          <p className="ProductBasePriceInfo__points">
            {formatByCurrency(
              Number(unitPoints ?? 0),
              getFormatPointsOption(selectedCountry),
              true
            )}
          </p>
        </>
      ) : null}
    </Styles>
  );
};

ProductBasePriceInfo.defaultProps = {
  className: ""
};

export default ProductBasePriceInfo;
