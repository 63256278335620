import { useFetchCurrentPrivacyPolicies } from "@simple/services";
import React, { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";

import { SignUpUserFormValues } from "../SignUpUserForm/SignUpUserForm.types";
import Styles from "./PrivacyPoliciesWithCheckboxes.styles";
import { PrivacyPoliciesWithCheckboxesProps as Props } from "./PrivacyPoliciesWithCheckboxes.types";
import Checkbox from "components/global/Checkbox/Checkbox";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

const PrivacyPoliciesWithCheckboxes: React.FC<Props> = props => {
  const { textStyle } = props;
  const auth = useAuth();
  const fetchCurrentPrivacyPolicies = useFetchCurrentPrivacyPolicies(auth);
  const { data: currentPrivacyPolicies } = fetchCurrentPrivacyPolicies;
  const formMethods = useFormContext<SignUpUserFormValues>();
  const t = useI18n();
  const [showConfig, setShowConfig] = useState(false);

  const { watch, setValue, register, formState } = formMethods;

  const policies = watch("privacyPolicies");
  const { acceptPrivacyPolicies } = formState.errors ?? {};

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const isChecked = e?.currentTarget?.checked;
    setValue(`privacyPolicies.${id}`, { id, isActive: isChecked });
  };

  return (
    <Styles className="PrivacyPoliciesWithCheckboxes">
      <Checkbox
        {...register("acceptPrivacyPolicies")}
        className="PrivacyPoliciesWithCheckboxes__checkbox PrivacyPoliciesWithCheckboxes__checkbox--border"
        label={
          <p className={`PrivacyPoliciesWithCheckboxes__text ${textStyle}`}>
            {t.auth.signUpUser.acceptPrivacyPolicies}
            <span
              onClick={e => {
                e.preventDefault();
                setShowConfig(prev => !prev);
              }}
              className={`PrivacyPoliciesWithCheckboxes__config ${textStyle}`}
            >
              {t.auth.signUpUser.configPrivacyPolicies}
            </span>
          </p>
        }
        errorMessage={acceptPrivacyPolicies?.message}
      />
      {showConfig ? (
        <section className="PrivacyPoliciesWithCheckboxes__configWrapper">
          <p className={`PrivacyPoliciesWithCheckboxes__text ${textStyle}`}>
            {t.auth.signUpUser.consent}
          </p>

          {currentPrivacyPolicies?.map(currentPrivacyPolicy => {
            const { id, description, name } = currentPrivacyPolicy;
            const isActive = policies?.[id]?.isActive;

            return (
              <Checkbox
                key={`${id}-${isActive}`}
                name={name}
                defaultChecked={isActive}
                className="PrivacyPoliciesWithCheckboxes__checkbox"
                label={
                  <p
                    className={`PrivacyPoliciesWithCheckboxes__text ${textStyle}`}
                  >
                    {description}
                  </p>
                }
                onChange={e => handleCheckbox(e, id)}
              />
            );
          })}

          <p className={`PrivacyPoliciesWithCheckboxes__text ${textStyle}`}>
            {t.auth.signUpUser.infoPrivacyPolicies}
          </p>
        </section>
      ) : null}
    </Styles>
  );
};

PrivacyPoliciesWithCheckboxes.defaultProps = {};

export default PrivacyPoliciesWithCheckboxes;
