import styled from "styled-components";

import { OutOfServiceModalStyledProps as Props } from "./OutOfServiceModal.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { mobile, tablet, desktop } = CONSTANTS.BREAKPOINTS;

const { fonts } = variables;

const OutOfServiceModalStyled = styled.div<Props>`
  background: var(--palette-primary);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .OutOfServiceModal {
    &__wave {
      width: auto;
      height: auto;

      &--top {
        align-self: flex-end;
      }

      &--bottom {
        transform: rotate(180deg);
        align-self: flex-start;
      }

      @media (max-width: ${tablet}px) {
        width: 30rem;
        height: 22rem;
      }

      @media (max-width: ${mobile}px) {
        width: 20rem;
        height: 15rem;
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 -16rem;
    }

    &__title {
      font-family: ${fonts.outService};
      font-size: 5.6rem;
      color: var(--palette-white);
      text-align: center;
      text-transform: uppercase;
      max-width: 58rem;
      padding: 2.4rem;
      z-index: 2;

      @media (max-width: ${tablet}px) {
        font-size: 3rem;
      }

      @media (max-width: ${mobile}px) {
        font-size: 2.4rem;
      }
    }

    &__description {
      font-family: ${fonts.outService};
      font-size: 2.4rem;
      color: var(--palette-white);
      text-align: center;
      text-transform: uppercase;
      z-index: 2;

      @media (max-width: ${tablet}px) {
        font-size: 1.4rem;
      }

      @media (max-width: ${mobile}px) {
        font-size: 1rem;
      }
    }

    &__image {
      max-width: 70rem;
      max-height: 32rem;

      @media (max-width: ${desktop}px) {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default OutOfServiceModalStyled;
