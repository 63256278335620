import { useStores } from "@simple/contexts";
import { Clickable } from "artisn-ui-react";
import { Store } from "artisn/types";
import React, { useState, useEffect } from "react";

import ChooseStore from "../ChooseStore/ChooseStore";
import ChooseStoreInMap from "../ChooseStoreInMap/ChooseStoreInMap";
import Styles, { ModalHeaderStyled } from "./ChooseStoreModal.styles";
import { ChooseStoreModalProps as Props } from "./ChooseStoreModal.types";
import Modal from "components/global/Modal/Modal";
import StoreDetailsPickup from "components/global/StoreDetailsPickup/StoreDetailsPickup";

import ChevronLeftSVG from "../../../../public/assets/images/chevron-left-black.svg";
import CloseSVG from "../../../../public/assets/images/close.svg";

const ChooseStoreModal: React.FC<Props> = props => {
  const { opened, onClose } = props;
  const { setSelectedStore } = useStores();
  const [previousStep, setPreviousStep] = useState(1);
  const [step, setStep] = useState(1);
  const [mapStore, setMapStore] = useState<Store>();
  const [title, setTitle] = useState("Tiendas");

  const backHandler = () => {
    setStep(prev => {
      if (previousStep === 1) return 1;
      return prev - 1;
    });
  };

  const closeHandler = () => {
    setStep(1);
    setMapStore(undefined);
    onClose?.();
  };

  const onSelectedStore = (store: Store) => {
    setSelectedStore(store);
    setMapStore(store);
    onClose?.();
  };

  const modalHeaderNode = (
    <ModalHeaderStyled step={step}>
      {step > 1 ? (
        <Clickable className="ChooseStoreModal__go-back" onClick={backHandler}>
          <ChevronLeftSVG />
        </Clickable>
      ) : null}
      <p className="ChooseStoreModal__header">{title}</p>
    </ModalHeaderStyled>
  );

  const chooseStoreNode =
    step === 1 ? (
      <ChooseStore
        mapStore={mapStore}
        setStep={setStep}
        setPreviousStep={setPreviousStep}
        setMapStore={setMapStore}
        onSelectedStore={onSelectedStore}
        onClose={closeHandler}
      />
    ) : null;

  const chooseStoreInMapNode =
    step === 2 ? (
      <ChooseStoreInMap
        mapStore={mapStore}
        setStep={setStep}
        onClose={onClose}
        setMapStore={setMapStore}
        setPreviousStep={setPreviousStep}
      />
    ) : null;

  const storeDetailsNode =
    step === 3 ? <StoreDetailsPickup store={mapStore} /> : null;

  useEffect(() => {
    if (step === 1) setTitle("Tiendas");
    if (step === 2) setTitle("Buscar en el mapa");
    if (step === 3) setTitle("Información");
  }, [step]);

  return (
    <Modal
      {...props}
      closeOnClickOutside
      opened={opened}
      header={modalHeaderNode}
      backdropConfig={{ onClick: closeHandler }}
      onClose={closeHandler}
      closeIcon={<CloseSVG />}
    >
      <Styles className="ChooseStoreModal">
        {chooseStoreNode}
        {chooseStoreInMapNode}
        {storeDetailsNode}
      </Styles>
    </Modal>
  );
};

ChooseStoreModal.defaultProps = {};

export default ChooseStoreModal;
