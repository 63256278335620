import { Providers } from "artisn/analytics";
import { initAuth, getServices } from "artisn/auth";
import { createApp } from "artisn/init";

import CONSTANTS from "config/constants";

const { ARTISN, GENERAL, API } = CONSTANTS;
const { PLATFORM } = GENERAL;
const { ACCOUNT_ID } = ARTISN;
const { API_URL } = API;

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyDFtI-4fWv7uNgBYPmJCe6hZljEfo0e6H0",
      authDomain: "pure-nature-dev-72352.firebaseapp.com",
      databaseURL: "https://pure-nature-dev-72352-default-rtdb.firebaseio.com",
      projectId: "pure-nature-dev-72352",
      storageBucket: "pure-nature-dev-72352.appspot.com",
      messagingSenderId: "831189828575",
      appId: "1:831189828575:web:449752f6d103500976fa6a",
      measurementId: "G-NH75QH0PBG",
      // TODO: add real credentials
      mapsApiKey: "AIzaSyD14HFDRcxM-uJ_th6YVD_hi0CGH6KXS08",
      facebookAppId: "793999348613204"
    },
    production: {
      apiKey: "AIzaSyCeW4U2H3ixer3x3zCjRHX-h4YOg9qMu7o",
      authDomain: "pure-nature-prod-trade.firebaseapp.com",
      databaseURL: "https://pure-nature-prod-trade-default-rtdb.firebaseio.com",
      projectId: "pure-nature-prod-trade",
      storageBucket: "pure-nature-prod-trade.appspot.com",
      messagingSenderId: "205548550051",
      appId: "1:205548550051:web:5fbadc17262602a6c3cc16",
      measurementId: "G-MP3671E7WK",
      // TODO: add real credentials
      mapsApiKey: "AIzaSyD6tUAeJrdCpI_ILqwmZSvK51NxA690GE8",
      facebookAppId: "373499305564915"
    }
  }[env];
})();

export const artisn = createApp(
  {
    projectId: getENVs?.projectId ?? "",
    apiKey: getENVs?.apiKey ?? "",
    authDomain: `${getENVs?.projectId}.firebaseapp.com`,
    accountId: ACCOUNT_ID,
    platform: PLATFORM
  },
  API_URL
);

export const auth = () => initAuth(artisn);
export const Services = getServices(artisn);
export const firestore = artisn?.__internals__!.firestore();

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  trackingId: getENVs?.facebookAppId ?? ""
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
