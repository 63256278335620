import styled from "styled-components";

import { TermsWithCollapseTextStyledProps as Props } from "./TermsWithCollapseText.types";

const TermsWithCollapseTextStyled = styled.div<Props>`
  .TermsWithCollapseText {
    &__checkbox {
      padding-bottom: 3rem;
    }

    &__terms {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.6;
      color: var(--palette-white);

      & > a {
        color: var(--palette-white);
        padding-left: 0.4rem;
        text-decoration: none;
        font-weight: 500;
      }

      &--link:hover {
        color: var(--palette-white);
        font-weight: 600;
      }
    }

    &__card {
      background-color: var(--palette-white);
      padding: 1.6rem;
      border-radius: 1.6rem;
      margin-bottom: 3rem;

      &__title {
        padding-bottom: 0.8rem;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    &__privacyDescription {
      color: var(--palette-gray-s0-l35);
      line-height: 2rem;
      font-weight: 500;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden !important;
      text-overflow: ellipsis;
      -webkit-line-clamp: ${props => (props.seeMore ? 4 : "none")};
      margin-bottom: 0.8rem;

      & > span {
        display: block;
        margin-top: 0.8rem;
      }
    }

    &__seemore {
      padding: 0;
    }
  }
`;

export default TermsWithCollapseTextStyled;
