import { useGeo } from "@simple/contexts";
import { useCountries } from "@simple/contexts";
import { useFetchCoordsFromGoogleAddress } from "@simple/services";
import { useFetchAutocompletePrediction } from "@simple/services";
import { SearchBar } from "artisn-ui-react";
import { events } from "artisn/analytics";
import React, { useEffect, useState } from "react";

import InfoCard from "../InfoCard/InfoCard";
import Styles, { SearchAddressContentStyled } from "./SearchAddress.styles";
import { SearchAddressProps as Props } from "./SearchAddress.types";
import { getENVs } from "config/artisn.config";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import { Google } from "types/geo.types";
import { dropdownConfigs } from "utils/common.utils";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { debounce } from "utils/performance.utils";

import LoadingSVG from "../../../../public/assets/images/loading.svg";

const { logFindLocation } = events.geo;

const SearchAddress: React.FC<Props> = props => {
  const { onPredictedPlaces } = props;
  const { showSearchDropdown = true, onChange } = props;
  const { onInputChange } = props;
  const { selectedCountry } = useCountries();
  const { lat, lng } = selectedCountry;
  const [query, setQuery] = useState<string | undefined>();
  const { selectedCoordinates } = useGeo();
  const { analyticsInitialized } = useAnalytics();
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPlaceName, setSelectedPlaceName] = useState("");
  const {
    data: predictedPlaces,
    isLoading: predictedPlacesLoading,
    error: predictedPlacesError
  } = useFetchAutocompletePrediction(
    query,
    selectedCoordinates ?? { lat, lng }
  );
  const { data: place, error: placeError } = useFetchCoordsFromGoogleAddress(
    getENVs?.apiKey ?? "",
    selectedPlaceName
  );
  const submitHandler = (query: string) => {
    setQuery(query);
  };

  const changeHandler = (item: Google.Autocomplete) => {
    const { description } = item;

    setSelectedPlaceName(description);
    setShowDropdown(false);
  };

  const onInputClick = () => {
    setShowDropdown(true);
  };

  const clearHandler = () => {
    setQuery(undefined);
    setShowDropdown(false);
  };

  const queryChangeHandler = debounce((value: string) => {
    if (value.length < 3) return;
    setQuery(value);
    onInputChange?.(value);
  }, 500);

  useEffect(() => {
    if (!place || !analyticsInitialized) return;
    logFindLocation();
    onChange?.(place);
  }, [analyticsInitialized, onChange, place]);

  useEffect(() => {
    if (!predictedPlacesError) return;
    dismissErrorNotification();
    createErrorNotification(
      "Hubo un error al traer el resultado de la búsqueda"
    );
    console.error(predictedPlacesError);
  }, [predictedPlacesError]);

  useEffect(() => {
    if (!placeError) return;
    dismissErrorNotification();
    createErrorNotification("Hubo un error al traer la dirección buscada");
    console.error(placeError);
  }, [placeError]);

  useEffect(() => {
    if (!predictedPlaces) return;
    onPredictedPlaces?.(predictedPlaces);
  }, [predictedPlaces, onPredictedPlaces]);

  return (
    <Styles className="SearchAddress">
      <SearchBar
        className="SearchAddress__search-bar field"
        placeholder="Busca una dirección"
        onSubmit={submitHandler}
        onInputFocus={() => setSelectedPlaceName("")}
        onInputClick={onInputClick}
        dropdownConfigs={{ ...dropdownConfigs, showDropdown }}
        onClear={clearHandler}
        onInputBlur={() => setShowDropdown(false)}
        onChange={queryChangeHandler}
        SearchIcon={
          predictedPlacesLoading ? (
            <LoadingSVG
              className="SearchAddress__loading"
              viewBox="0 0 66 66"
            />
          ) : null
        }
      >
        {showSearchDropdown ? (
          <SearchAddressContentStyled className="SearchAddressContent">
            {predictedPlaces?.map((item, index) => {
              const { description } = item;
              return (
                <InfoCard
                  className="SearchAddressContent__address-info"
                  key={index}
                  value={description}
                  onContentClick={() => changeHandler(item)}
                />
              );
            })}
            {predictedPlaces?.length === 0 ? (
              <p className="SearchAddressContent__empty">
                No se encontraron resultados
              </p>
            ) : null}
          </SearchAddressContentStyled>
        ) : null}
      </SearchBar>
    </Styles>
  );
};

SearchAddress.defaultProps = {};

export default SearchAddress;
