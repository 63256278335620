import styled from "styled-components";

import { EmptyStateStyledProps as Props } from "./EmptyState.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { tablet } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const EmptyStateStyled = styled.div<Props>`
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: ${primary};

  .EmptyState {
    &__title {
      color: var(--palette-black-s0-l10);
      font-size: 2.4rem;
      line-height: 3.2rem;
      padding-top: 2rem;
      font-weight: 700;
      text-align: center;
    }

    &__message {
      color: var(--palette-gray-s0-l35);
      font-size: 1.6rem;
      line-height: 1.8rem;
      padding-top: 0.8rem;
      padding-bottom: 2.4rem;
      text-align: center;

      @media (max-width: ${tablet - 1}px) {
        width: fit-content;
      }
    }

    &__button {
      margin-top: 1.6rem;
      color: var(--palette-white);
      font-weight: 700;
      font-size: 1.6rem;
    }
  }
`;

export default EmptyStateStyled;
