import React, { useMemo } from "react";

import Button from "../Button/Button";
import Styles from "./Counter.styles";
import { CounterProps as Props } from "./Counter.types";
import useCounter from "hooks/useCounter/useCounter";
import { UseCounterConfig } from "hooks/useCounter/useCounter.types";

import SubtractSVG from "../../../../public/assets/images/minus.svg";
import PlusSVG from "../../../../public/assets/images/plus.svg";

const Counter: React.FC<Props> = props => {
  const { className, disabled, value } = props;
  const { min = 0, max = 100, initialValue = 0 } = props;
  const { onChange, onAdd } = props;
  const { onSubtract } = props;
  const { minDisabled: minDisabledProp } = props;
  const { maxDisabled: maxDisabledProp } = props;

  const config: UseCounterConfig = useMemo(
    () => ({
      min,
      max,
      value,
      initialValue,
      onChange,
      onAdd,
      onSubtract
    }),
    [initialValue, max, min, onAdd, onChange, onSubtract, value]
  );

  const { add, subtract, quantity } = useCounter(config);

  const minDisabled = quantity <= min || disabled || minDisabledProp;
  const maxDisabled = quantity >= max || disabled || maxDisabledProp;

  return (
    <Styles className={`CounterUI ${className}`} disabled={disabled}>
      {!disabled ? (
        <Button
          onClick={subtract}
          className="Counter__button"
          disabled={minDisabled}
        >
          <SubtractSVG className="Counter__icon" />
        </Button>
      ) : null}
      <p className="Counter__quantity">{quantity}</p>
      {!disabled ? (
        <Button
          onClick={add}
          className="Counter__button"
          disabled={maxDisabled}
        >
          <PlusSVG className="Counter__icon" />
        </Button>
      ) : null}
    </Styles>
  );
};

Counter.defaultProps = {
  disabled: false
};

export default Counter;
