import styled from "styled-components";

import { ProductBasePriceInfoStyledProps as Props } from "./ProductBasePriceInfo.types";
import variables from "styles/util/variables";

const { primary } = variables.fonts;

const ProductBasePriceInfoStyled = styled.div<Props>`
  font-family: ${primary};
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.8rem;
  padding-bottom: 1.6rem;
  color: var(--palette-black-s0-l10);

  .ProductBasePriceInfo {
    &__dollars {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l35);
    }

    &__point {
      background-color: var(--palette-black-s0-l10);
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      margin: 0 0.8rem;
    }

    &__points {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l35);
    }
  }
`;

export default ProductBasePriceInfoStyled;
