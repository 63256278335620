import { CurrentPrivacyPolicy, UserPrivacyPolicy } from "@simple/services";
import { UserPrivacyPolicyPayload } from "@simple/services";

/**
 * Function to check if all current privacy policy IDs exist in User Privacy Policies
 *
 * @param {UserPrivacyPolicy[]} userPrivacyPolicies Privacy Policies of the User
 * @param {CurrentPrivacyPolicy[]} currentPrivacyPolicies Current Privacy Policies
 * @returns {boolean}
 */
export const checkIfUserHasReadPolicies = (
  userPrivacyPolicies?: UserPrivacyPolicy[],
  currentPrivacyPolicies?: CurrentPrivacyPolicy[]
) => {
  if (!userPrivacyPolicies || !currentPrivacyPolicies) return false;

  return currentPrivacyPolicies.every(currentPolicy =>
    userPrivacyPolicies.some(userPolicy => {
      return userPolicy.privacyPolicyId === currentPolicy.id;
    })
  );
};

/**
 * Function to set initial values to use on the terms form
 *
 * @since 3.4.0
 * @param {UserPrivacyPolicy[]} userPrivacyPolicies User privacy policies.
 * @param {CurrentPrivacyPolicy[]} currentPrivacyPolicies Current privacy policies
 * @returns {Record<string,UserPrivacyPolicyPayload>}
 */
export const createUserPrivacyPoliciesIndex = (
  userPrivacyPolicies?: UserPrivacyPolicy[],
  currentPrivacyPolicies?: CurrentPrivacyPolicy[]
) => {
  const userPrivacyPoliciesIndex = {} as Record<
    string,
    UserPrivacyPolicyPayload
  >;

  if (!currentPrivacyPolicies) return userPrivacyPoliciesIndex;

  currentPrivacyPolicies.forEach(item => {
    userPrivacyPoliciesIndex[item.id] = {
      id: item.id,
      isActive: !!userPrivacyPolicies?.find(
        userPrivacyPolicy => userPrivacyPolicy.privacyPolicyId === item.id
      )?.status
    };
  });
  return userPrivacyPoliciesIndex;
};
