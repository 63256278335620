import { useCountries } from "@simple/contexts";
import { titleCase } from "@simple/utils";
import { ProductCard as ProductCardUI } from "artisn-ui-react";
import { useProductCard } from "artisn-ui-react";
import { formatByCurrency } from "artisn-ui-react";
import React from "react";

import { transformProductModifiers } from "./HorizontalProductCard.helpers";
import Styles from "./HorizontalProductCard.styles";
import { HorizontalProductCardProps as Props } from "./HorizontalProductCard.types";
import ProductBasePriceInfo from "components/global/ProductBasePriceInfo/ProductBasePriceInfo";
import ProductCardPlaceholder from "components/global/ProductCard/ProductCard.placeholder";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";

const { Image, Name } = ProductCardUI;

const HorizontalProductCard: React.FC<Props> = props => {
  const { product, className, carousel = false } = props;
  const { modifiers, fromShoppingCart } = props;
  const { onClick = () => {} } = props;
  const { attributes } = product ?? {};
  const enhancedProduct = carousel
    ? {
        ...product,
        images: [product.images[0]]
      }
    : {
        ...product,
        attributes: {
          ...attributes,
          showInMenu: !attributes.showInMenu
            ? !!fromShoppingCart
            : attributes.showInMenu
        }
      };
  // @ts-ignore TODO: Look the types
  const { billProduct } = product ?? {};
  const { total, amount } = billProduct ?? {};
  const { decimals } = useCountries().selectedCountry;
  const { totals } = useProductCard(product, { decimals });
  const { netPrice } = totals ?? {};
  const defaultFormatter = useDefaultCurrencyFormatter();
  const arrModifiers = transformProductModifiers(modifiers);
  const { additionalInfo } = product ?? {};
  const { basePrice } = additionalInfo ?? {};

  return (
    <Styles className={`HorizontalProductCard ${className}`} onClick={onClick}>
      <ProductCardUI
        className="HorizontalProductCard__product-details"
        product={enhancedProduct}
        placeholder={<ProductCardPlaceholder />}
      >
        <Image
          className="HorizontalProductCard__image"
          width={100}
          height={100}
          alt={enhancedProduct.name}
        />
        <Name className="HorizontalProductCard__name" />
        {arrModifiers?.length ? (
          <div className="HorizontalProductCard__description">
            {arrModifiers.map((modifier, index) => {
              return (
                <span key={`${index}-${modifier}`}>{titleCase(modifier)}</span>
              );
            })}
          </div>
        ) : null}
        <ProductBasePriceInfo
          className="HorizontalProductCard__price"
          productTotals={totals}
          showUnits
          amount={amount}
          basePrice={basePrice}
          showAmount={!arrModifiers?.length}
        />
        <div className="HorizontalProductCard__total">
          {formatByCurrency(total ?? netPrice, defaultFormatter)}
        </div>
      </ProductCardUI>
    </Styles>
  );
};

HorizontalProductCard.defaultProps = {
  className: ""
};

export default HorizontalProductCard;
