import React from "react";

import Styles from "./StoreMarker.styles";
import { StoreMarkerProps as Props } from "./StoreMarker.types";
import { defaultFunction } from "utils/common.utils";

import StorePurpleSVG from "../../../../../public/assets/images/local-purple.svg";
import StoreWhiteSVG from "../../../../../public/assets/images/local-white.svg";

const StoreMarker: React.FC<Props> = props => {
  const { isActive = false, onClick = defaultFunction } = props;

  return (
    <Styles className="StoreMarker" isActive={isActive} onClick={onClick}>
      {isActive ? <StoreWhiteSVG /> : <StorePurpleSVG />}
    </Styles>
  );
};

StoreMarker.defaultProps = {};

export default StoreMarker;
