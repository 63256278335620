import { useShoppingCart } from "@simple/contexts";

import { getSubtotalFromShoppingCart } from "utils/shoppingCart.utils";
import { getProductsSubtotalUtility } from "utils/shoppingCart.utils";
import { getDiscountTotalUtility } from "utils/shoppingCart.utils";

const useShoppingCartCalculations = () => {
  const { shoppingCart } = useShoppingCart();

  const { shippingCost, billTotal } = shoppingCart ?? {};

  const { taxTotal: shippingCostTaxTotal } = shippingCost ?? {};
  const { grossPrice: shippingCostGross } = shippingCost ?? {};
  const { netPrice: shippingCostNet = 0 } = shippingCost ?? {};

  // If back calculate totals we just use their billTotal object, otherwise we do
  // It happens when an benefit is applied
  if (billTotal && Object.keys(billTotal).length !== 0) {
    const { NORMAL } = billTotal;
    // @ts-ignore check for discountTotal when Update artisn SDK
    const { subtotalBeforeTaxesBeforeDiscounts = 0, discountTotal = 0 } =
      NORMAL ?? {};
    // @ts-ignore
    const { total = 0, taxTotal } = NORMAL ?? {};

    return {
      subTotal: subtotalBeforeTaxesBeforeDiscounts,
      totalDiscount: Number(discountTotal),
      shippingCostNet: shippingCostNet,
      calculatedTaxes: taxTotal ?? 0,
      totalToPay: +total.toFixed(2)
    };
  }

  const subTotalWithOutBenefit = getSubtotalFromShoppingCart(shoppingCart);

  const subTotalBenefit = getProductsSubtotalUtility(shoppingCart);
  const netSubTotal = subTotalBenefit ?? subTotalWithOutBenefit;

  const totalDiscount = getDiscountTotalUtility(shoppingCart);

  const { taxes: taxesShoppingCart } = shippingCost ?? {};
  let taxPercentage = 0;
  if (taxesShoppingCart && taxesShoppingCart.length > 0) {
    const percentageValue = taxesShoppingCart[0].percentage;
    taxPercentage = percentageValue / 100;
  }
  const subTotalDiscountApplied = +(netSubTotal - totalDiscount).toFixed(2);
  const calculatedTaxes = +(subTotalDiscountApplied * taxPercentage).toFixed(2);

  const totalToPayWithDiscount =
    subTotalDiscountApplied +
    calculatedTaxes +
    (shippingCostGross ?? 0) +
    (shippingCostTaxTotal ?? 0);
  const totalToPayWithOutDiscount = calculatedTaxes + netSubTotal ?? 0;
  const totalToPay = totalDiscount
    ? totalToPayWithDiscount
    : totalToPayWithOutDiscount;

  return {
    subTotal: netSubTotal,
    totalDiscount,
    shippingCostNet,
    calculatedTaxes,
    totalToPay: +totalToPay.toFixed(2)
  };
};

export default useShoppingCartCalculations;
