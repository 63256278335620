import { useCatalogues } from "@simple/contexts";
import { useShoppingCart } from "@simple/contexts";
import { sanitizeQueryParams } from "@simple/utils";
import { checkInit } from "artisn/shopping-cart";
import { getShoppingCart, deleteShoppingCart } from "artisn/shopping-cart";
import { createShoppingCart, mergeShoppingCart } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

import InfoActionModal from "../InfoActionModal/InfoActionModal";
import Styles from "./ShareCartModal.styles";
import { ShareCartModalProps as Props } from "./ShareCartModal.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingCost from "hooks/useShippingCost";
import { removeQueryParam } from "utils/common.utils";

import CartErrorSVG from "../../../../public/assets/images/cart-error.svg";

const { ACCOUNT_ID, SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;

const ShareCartModal: React.FC<Props> = props => {
  const { query, asPath, replace } = useRouter();
  const { catalogueId } = useCatalogues()?.selectedCatalogue ?? {};
  const { shoppingCart } = useShoppingCart();
  const { shareId } = sanitizeQueryParams(query);
  const { name: shoppingCartName = SHOPPING_CART_DEFAULT_NAME } =
    shoppingCart ?? {};
  const newPath = useMemo(() => removeQueryParam(asPath, "shareId"), [asPath]);
  const shippingCost = useShippingCost();
  const { isAnonymous } = useAuth();

  const replaceShoppingCart = async () => {
    if (!shareId) return;

    const incomingCart = await getShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      accountId: ACCOUNT_ID,
      customerId: shareId,
      anonymous: isAnonymous
    });

    if (!incomingCart) {
      await replace(newPath, undefined, { shallow: true });
      return;
    }

    if (shoppingCartName) {
      await deleteShoppingCart({ shoppingCartName, anonymous: isAnonymous });
    }

    await createShoppingCart(
      {
        anonymous: isAnonymous
      },
      {
        stores: incomingCart.stores,
        channelId: +catalogueId,
        shippingCost,
        name: shoppingCartName
      }
    );
    await replace(newPath, undefined, { shallow: true });
  };

  const localMergeShoppingCart = async () => {
    if (!shareId) return;

    const incomingCart = await getShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      customerId: shareId,
      anonymous: isAnonymous
    });

    if (!incomingCart) {
      await replace(newPath, undefined, { shallow: true });
      return;
    }

    await mergeShoppingCart(incomingCart, {
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous
    });

    await replace(newPath, undefined, { shallow: true });
  };

  return (
    <Styles className="ShareCartModal">
      <InfoActionModal
        title="¿Deseas reemplazar tu carrito anterior?"
        description="Estas creando un nuevo carrito, deseas mantener el que tenias guardado anteriormente"
        cancelText="Mantener ambos"
        confirmText="Crear nuevo carrito"
        confirmAction={replaceShoppingCart}
        cancelAction={localMergeShoppingCart}
        opened={!!shareId && !!shoppingCart && checkInit()}
        icon={<CartErrorSVG />}
      />
    </Styles>
  );
};

ShareCartModal.defaultProps = {};

export default ShareCartModal;
