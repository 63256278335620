import { Modal } from "artisn-ui-react";
import React from "react";

import { backdropConfig } from "./OutOfServiceModal.helpers";
import Styles from "./OutOfServiceModal.styles";
import { OutOfServiceModalProps as Props } from "./OutOfServiceModal.types";
import useI18n from "hooks/useI18n";

import OutServiceSvg from "/public/assets/images/out-service.svg";
import WaveSvg from "/public/assets/images/wave-yellow.svg";

const OutOfServiceModal: React.FC<Props> = props => {
  const { open } = props;
  const t = useI18n().outService;

  return (
    <Modal
      backdropConfig={backdropConfig}
      className="OutOfServiceModal__modal"
      opened={open}
    >
      <Styles className="OutOfServiceModal">
        <WaveSvg
          className="OutOfServiceModal__wave OutOfServiceModal__wave--top"
          viewBox="0 0 512 371"
          preserveAspectRatio="xMidYMid meet"
        />
        <div className="OutOfServiceModal__content">
          <h1 className="OutOfServiceModal__title">{t.title}</h1>
          <p className="OutOfServiceModal__description">{t.description}</p>

          <OutServiceSvg
            className="OutOfServiceModal__image"
            viewBox="0 0 934 604"
            preserveAspectRatio="xMidYMid meet"
          />
        </div>

        <WaveSvg
          className="OutOfServiceModal__wave OutOfServiceModal__wave--bottom"
          viewBox="0 0 512 371"
          preserveAspectRatio="xMidYMid meet"
        />
      </Styles>
    </Modal>
  );
};

OutOfServiceModal.defaultProps = {};

export default OutOfServiceModal;
