import { NotificationsContainer } from "artisn-ui-react";
import React, { useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
// import { ReactQueryDevtools } from "react-query/devtools"
import { QueryClientProvider, QueryClient } from "react-query";
import { Hydrate } from "react-query/hydration";

import { ProvidersProps as Props } from "./Providers.types";
import CheckoutProvider from "components/checkout/Checkout/context/checkout/checkout.context";
import Error from "components/error/Error/Error";
import { queryClient } from "config/reactQuery.config";
import ArtisnProvider from "containers/ArtisnProvider/ArtisnProvider";
import GlobalsProvider from "contexts/globals/globals.context";
import ThemeProvider from "contexts/theme/theme.context";

const Providers: React.FC<Props> = props => {
  const { children, dehydratedState } = props;
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) queryClientRef.current = queryClient;

  return (
    <GlobalsProvider>
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={Error}>
          <QueryClientProvider client={queryClientRef.current}>
            <Hydrate state={dehydratedState}>
              {/* {process.env.NODE_ENV !== "test" ? <ReactQueryDevtools /> : null} */}
              <ArtisnProvider>
                <CheckoutProvider>
                  <NotificationsContainer />
                  {children}
                </CheckoutProvider>
              </ArtisnProvider>
            </Hydrate>
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </GlobalsProvider>
  );
};

Providers.defaultProps = {};

export default Providers;
