import { CardPaymentFormValues } from "@simple/services";
import { PaymentStatusPlaceToPay } from "@simple/types";
import { BaseBillingData } from "artisn/types";
import React, { createContext, useMemo, useReducer, useState } from "react";

import { userInitialState } from "./checkout.context.helpers";
import { userReducer } from "./checkout.context.helpers";
import { CheckoutProviderProps as Props } from "./checkout.context.types";
import { UserValues } from "./checkout.context.types";
import { PlaceOrderStep } from "./checkout.context.types";
import { CheckoutProviderValue } from "./checkout.context.types";
import { PICK_UP_OPTIONS } from "config/constants";
import { PickUpOption } from "types/common.types";

// @ts-ignore
export const CheckoutContext = createContext<CheckoutProviderValue>();

const CheckoutProvider: React.FC<Props> = props => {
  const [userFormValues, setUserFormValues] = useState<
    UserValues | undefined
  >();
  const [cardFormValues, setCardFormValues] = useState<CardPaymentFormValues>();
  const [status, setStatus] = useState<PaymentStatusPlaceToPay>();

  const [userError, setUserError] = useState<string>();
  const [transferAnonymousId, setTransferAnonymousId] = useState<string>();
  const [step, setStep] = useState<PlaceOrderStep>();
  const [password, setPassword] = useState<string>();
  const [anonymousUserValues, setAnonymousUserValue] = useReducer(
    userReducer,
    userInitialState
  );
  const [selectedPickUpOption, setSelectedPickUpOption] =
    useState<PickUpOption>(PICK_UP_OPTIONS[0]);
  const [anonymousBillingData, setAnonymousBillingData] =
    useState<BaseBillingData>();

  const setSelectedPickUpOptionHandler = (value: string) => {
    const selectedOption = PICK_UP_OPTIONS?.find(item => item.id === +value);

    if (!selectedOption) {
      throw new Error(
        `"${selectedOption}" does not correspond to a valid pick up option ID`
      );
    }

    setSelectedPickUpOption(selectedOption);
  };

  const value: CheckoutProviderValue = useMemo(() => {
    return {
      userFormValues,
      setUserFormValues,
      cardFormValues,
      setCardFormValues,
      status,
      setStatus,
      userError,
      setUserError,
      transferAnonymousId,
      setTransferAnonymousId,
      step,
      setStep,
      password,
      setPassword,
      anonymousUserValues,
      setAnonymousUserValue,
      selectedPickUpOption,
      setSelectedPickUpOption: setSelectedPickUpOptionHandler,
      anonymousBillingData,
      setAnonymousBillingData
    };
  }, [
    userFormValues,
    cardFormValues,
    status,
    userError,
    transferAnonymousId,
    step,
    password,
    anonymousUserValues,
    selectedPickUpOption,
    anonymousBillingData
  ]);

  return (
    <CheckoutContext.Provider value={value}>
      {props.children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
