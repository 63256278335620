import styled from "styled-components";

import { ChooseAddressStyledProps as Props } from "./ChooseAddress.types";
import { DeleteAddressModalStyledProps as ModalProps } from "./ChooseAddress.types";
import InfoActionModal from "components/global/InfoActionModal/InfoActionModal";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const ChooseAddressStyled = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: ${props =>
    props.isActive ? "0.2rem solid var(--palette-primary);" : "none"};
  height: 4.8rem;
  padding: 0 2.4rem;
  padding-right: 1.6rem;
  background-color: ${props =>
    props.disabled ? "var(--palette-gray-s0-l98)" : "var(--palette-white)"};
  pointer-events: ${props => (props.disabled ? "none" : "initial")};

  @media (max-width: ${mobile}px) {
    padding: 0 1.6rem;
  }

  .ChooseAddress {
    &__loading {
      svg {
        height: 2rem;
        width: 2rem;
        animation: rotate 0.6s linear infinite;
      }
    }
  }
`;

export const DeleteAddressModal = styled(InfoActionModal)<ModalProps>`
  path {
    fill: var(--palette-black);
  }

  .DeleteAddressModal {
  }
`;

export default ChooseAddressStyled;
