import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

import { SignUpUserFormValues } from "../SignUpUserForm/SignUpUserForm.types";
import Styles from "./TermsWithCollapseText.styles";
import { TermsWithCollapseTextProps as Props } from "./TermsWithCollapseText.types";
import Button from "components/global/Button/Button";
import Checkbox from "components/global/Checkbox/Checkbox";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";

const { TERMS_URL } = CONSTANTS.EXTERNAL_URLS;

const TermsWithCollapseText: React.FC<Props> = props => {
  const { hideCheckboxTerm = false, styleCardPolicies } = props;
  const { register, formState } = useFormContext<SignUpUserFormValues>();
  const t = useI18n();
  const [seeMore, setSeeMore] = useState(false);

  const { terms } = formState.errors ?? {};

  return (
    <Styles className="TermsWithCollapseText" seeMore={seeMore}>
      {!hideCheckboxTerm ? (
        <Checkbox
          {...register("terms")}
          className="TermsWithCollapseText__checkbox"
          label={
            <p className="TermsWithCollapseText__terms">
              <span>{t.auth.signUpUser.acceptTerms}</span>
              <a
                className="TermsWithCollapseText__terms--link"
                href={TERMS_URL}
                target="_blank"
                rel="noreferrer"
              >
                {t.auth.signUpUser.terms}
              </a>
            </p>
          }
          errorMessage={terms?.message}
        />
      ) : null}

      <section className={`TermsWithCollapseText__card ${styleCardPolicies}`}>
        <h3 className="TermsWithCollapseText__card__title">
          {t.auth.signUpUser.privacyPolicy}
        </h3>
        <p className="TermsWithCollapseText__privacyDescription">
          {t.privacyModal.paragraph1}
          <span>{t.privacyModal.paragraph2}</span>
        </p>
        <Button
          type="LINK"
          className="TermsWithCollapseText__seemore"
          onClick={() => setSeeMore(prev => !prev)}
          role="button"
          htmlType="button"
        >
          {seeMore ? "Ver más" : "Ver menos"}
        </Button>
      </section>
    </Styles>
  );
};

TermsWithCollapseText.defaultProps = {};

export default TermsWithCollapseText;
