import { yupResolver } from "@hookform/resolvers/yup";
import { useCountries } from "@simple/contexts";
import { useShippingAddress } from "@simple/contexts";
import { useFetchProvinces, useFetchCantons } from "@simple/services";
import { PostShippingAddressPayload } from "@simple/services";
import { PutShippingAddressPayload } from "@simple/services";
import { useFetchShippingAddresses } from "@simple/services";
import { usePostShippingAddress } from "@simple/services";
import { usePutShippingAddress } from "@simple/services";
import { CommentBox, PhoneNumberInputValues } from "artisn-ui-react";
import { TextInput, PhoneNumberInput } from "artisn-ui-react";
import { events } from "artisn/analytics";
import { LivingPlace } from "artisn/types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { getSelectedPostPayload } from "./AddressForm.helpers";
import { AddressFormSchema, getDefaultNickname } from "./AddressForm.helpers";
import { getSelectedPutPayload } from "./AddressForm.helpers";
import Styles from "./AddressForm.styles";
import { AddressFormValues } from "./AddressForm.types";
import { AddressFormProps as Props } from "./AddressForm.types";
import Button from "components/global/Button/Button";
import Checkbox from "components/global/Checkbox/Checkbox";
import Divider from "components/global/Divider/Divider";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import { defaultFunction } from "utils/common.utils";
import { trimFields } from "utils/form.utils";

import HeartLineSVG from "../../../../public/assets/images/heart-line.svg";
import LocalSVG from "../../../../public/assets/images/local.svg";
import WinerySVG from "../../../../public/assets/images/winery.svg";

const { logAddShippingAddress, logUpdateShippingAddress } = events.shipping;

const AddressForm: React.FC<Props> = props => {
  const { className, initialValues, method, mapAddress } = props;
  const { onPressButton = defaultFunction, disabled, editAddress } = props;
  const t = useI18n();
  const [comment, setComment] = useState(() => {
    const fieldComment = initialValues?.livingPlace?.fields?.find(
      field => field.label === "comment"
    );
    return fieldComment?.value ?? "";
  });
  const auth = useAuth();

  const { data: addressList } = useFetchShippingAddresses(auth);
  const { countryISOCodes, selectedCountry } = useCountries();
  const { isAnonymous } = useAuth();
  const { setSelectedShippingAddress } = useShippingAddress();
  const { isoCode } = selectedCountry;
  const formMethods = useForm<AddressFormValues>({
    mode: "onChange",
    resolver: yupResolver(AddressFormSchema),
    defaultValues: {
      ...initialValues,
      nickname: getDefaultNickname(initialValues?.nickname),
      otherNickname: initialValues?.nickname,
      default: !addressList?.length ? true : initialValues?.default
    }
  });
  const { formState, watch, control, setValue } = formMethods;
  const { handleSubmit, register, getValues } = formMethods;
  const [errorMessage, setErrorMessage] = useState("");
  const [nicknameErrorMessage, setNicknameErrorMessage] = useState("");
  const [provinceError, setProvinceError] = useState("");
  const [cantonError, setCantonError] = useState("");
  const [parishError, setParishError] = useState("");

  const [phone, setPhone] = useState<PhoneNumberInputValues>({
    countryCallingCode: "",
    countryCode: isoCode,
    international: "",
    internationalRaw: "",
    isPossible: true,
    isValid: true,
    national: getValues().numberContactAddress ?? "",
    nationalRaw: ""
  });

  const watchNickname = watch("nickname");
  const watchOtherNickname = watch("otherNickname");
  const watchProvince = watch("province");
  const watchCanton = watch("canton");
  const watchParish = watch("parish");

  const { data: provinces } = useFetchProvinces();
  const { data: cantons } = useFetchCantons(watchProvince?.[0]);

  const livingPlaceFields = getValues().livingPlace?.fields;
  const defaultProvince = livingPlaceFields?.find(
    field => field.id === "province"
  )?.label;
  const defaultCanton = livingPlaceFields?.find(
    field => field.id === "canton"
  )?.label;
  const defaultParish = livingPlaceFields?.find(
    field => field.id === "parish"
  )?.label;
  const cantonNames = cantons?.map(canton => canton.cantonName);
  const parishes = cantons?.find(
    canton => canton.cantonName === watchCanton
  )?.parishes;

  const selectedNickname = watchNickname === "Otro";
  const showInput = selectedNickname
    ? "AddressForm__nickname-input--show"
    : null;
  const {
    mutateAsync: postMutate,
    reset: postReset,
    isLoading: isLoadingPost
  } = usePostShippingAddress(auth);
  const {
    mutateAsync: putMutate,
    reset: putReset,
    isLoading: isLoadingPut
  } = usePutShippingAddress(auth);
  const { reference } = formState.errors ?? {};
  const { numberContactAddress } = formState.errors ?? {};
  const { mainStreet, secondaryStreet } = formState.errors ?? {};

  const addLivingPlaceField = (
    newLivingPlace: LivingPlace,
    id: string,
    label: string
  ) => {
    newLivingPlace.fields.push({
      id,
      label,
      rules: { max: 0, min: 0 },
      messages: { max: "0", min: "0" }
    });
  };

  const createAddress = async (postAddress: PostShippingAddressPayload) => {
    const { mainStreet, secondaryStreet, number, nickname } = postAddress;
    const { province, canton, parish, livingPlace } = postAddress;
    const newLivingPlace = { ...livingPlace, fields: [...livingPlace.fields] };

    addLivingPlaceField(newLivingPlace, "province", province[1]);
    addLivingPlaceField(newLivingPlace, "canton", canton);
    addLivingPlaceField(newLivingPlace, "parish", parish);

    const payload = {
      ...postAddress,
      livingPlace: newLivingPlace
    };

    await postMutate(payload, {
      onError: () => {
        setErrorMessage(t.errors.createAddress);
        console.error("An error occurred in the POST request");
      },
      onSuccess: () => {
        logAddShippingAddress({
          address: `${mainStreet} ${secondaryStreet} ${number}`,
          alias: nickname
        });
        onPressButton();
        postReset();
      }
    });
  };

  const updateAddress = async (putAddress: PutShippingAddressPayload) => {
    const { mainStreet, secondaryStreet, number, id, livingPlace } = putAddress;
    const { province, canton, parish } = putAddress;
    const newLivingPlace = { ...livingPlace, fields: [...livingPlace.fields] };

    addLivingPlaceField(newLivingPlace, "province", province[1]);
    addLivingPlaceField(newLivingPlace, "canton", canton);
    addLivingPlaceField(newLivingPlace, "parish", parish);

    const payload = {
      ...putAddress,
      livingPlace: newLivingPlace
    };

    await putMutate(payload, {
      onError: () => {
        setErrorMessage(t.errors.updateAddress);
        console.error("An error occurred in the POST request");
      },
      onSuccess: () => {
        logUpdateShippingAddress({
          address: `${mainStreet} ${secondaryStreet} ${number}`,
          addressId: id,
          fields: Object.keys(formState.touchedFields)
        });
        onPressButton();
        putReset();
      }
    });
  };

  const submitHandler = (form: AddressFormValues) => {
    const newForm = trimFields(form);
    setErrorMessage("");
    if (selectedNickname && !watchOtherNickname) {
      setNicknameErrorMessage("Campo requerido");
      return;
    }

    if (!watchProvince || !watchProvince?.[0]) {
      setProvinceError("Campo requerido");
      return;
    }

    if (!watchCanton) {
      setCantonError("Campo requerido");
      return;
    }

    if (!watchParish) {
      setParishError("Campo requerido");
      return;
    }

    if (isAnonymous) {
      setSelectedShippingAddress({
        ...getSelectedPostPayload(
          newForm,
          mapAddress,
          selectedCountry,
          comment
        ),
        id: 1,
        createdAt: "",
        country: {
          id: CONSTANTS.ARTISN.DEFAULT_COUNTRY.id,
          name: CONSTANTS.ARTISN.DEFAULT_COUNTRY.name
        },
        //TODO: check types
        //@ts-ignore
        city: {
          id: 1,
          name: "Guayaquil"
        },
        sector: {
          id: 1,
          name: "Guayaquil"
        },
        zone: {
          id: 1,
          name: "Guayaquil",
          zipCode: "090101"
        }
      });
      onPressButton();
      return;
    }
    if (method === "POST") {
      createAddress(
        getSelectedPostPayload(newForm, mapAddress, selectedCountry, comment)
      );
    }
    if (method === "PUT") {
      updateAddress(
        getSelectedPutPayload(
          newForm,
          mapAddress,
          selectedCountry,
          initialValues!,
          editAddress!,
          comment
        )
      );
    }
  };

  const renderProvinces = (
    <div className="AddressForm__selectContent">
      <div className="AddressForm__selectItem">
        <label htmlFor={`province`} className="AddressForm__selectLabel">
          {t.selectAddress.province}
        </label>
        <select
          id={`province`}
          value={watchProvince}
          className="AddressForm__selectContent--select"
          onChange={e => {
            setProvinceError("");
            setValue("province", [
              e.target.value.split(",")[0],
              e.target.value.split(",")[1]
            ]);
            setValue("canton", "");
            setValue("parish", "");
          }}
          key={`province`}
        >
          <>
            <option value={["-1", ""]} key={`province--1`}>
              {t.selectAddress.selectProvince}
            </option>
            {provinces?.map(province => {
              return (
                <option
                  value={[province.id, province.provinceName]}
                  key={`province-${province.id}`}
                >
                  {province.provinceName}
                </option>
              );
            })}
          </>
        </select>
        <span className="AddressForm__error-message AddressForm__error-message-pl">
          {provinceError}
        </span>
      </div>
      <div className="AddressForm__selectItem">
        <label htmlFor={`canton`} className="AddressForm__selectLabel">
          {t.selectAddress.canton}
        </label>
        <select
          id={`canton`}
          value={watchCanton}
          className="AddressForm__selectContent--select"
          onChange={e => {
            setCantonError("");
            setValue("canton", e.target.value);
            setValue("parish", "");
          }}
          key={`canton`}
        >
          <>
            <option value={""} key={`canton--1`}>
              {t.selectAddress.selectCanton}
            </option>
            {cantonNames?.map((canton, id) => {
              return (
                <option value={canton} key={`canton-${id}`}>
                  {canton}
                </option>
              );
            })}
          </>
        </select>
        <span className="AddressForm__error-message AddressForm__error-message-pl">
          {cantonError}
        </span>
      </div>
      <div className="AddressForm__selectItem">
        <label htmlFor={`parish`} className="AddressForm__selectLabel">
          {t.selectAddress.parish}
        </label>
        <select
          id={`parish`}
          value={watchParish}
          className="AddressForm__selectContent--select"
          onChange={e => {
            setParishError("");
            setValue("parish", e.target.value);
          }}
          key={`parish`}
        >
          <>
            <option value={""} key={`parish--1`}>
              {t.selectAddress.selectParish}
            </option>
            {parishes?.map((parish, id) => {
              return (
                <option value={parish} key={`parish-${id}`}>
                  {parish}
                </option>
              );
            })}
          </>
        </select>
        <span className="AddressForm__error-message AddressForm__error-message-pl">
          {parishError}
        </span>
      </div>
    </div>
  );

  useEffect(() => {
    if (defaultCanton) setValue("canton", defaultCanton);
    if (defaultParish) setValue("parish", defaultParish);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCanton, defaultParish]);

  useEffect(() => {
    if (defaultProvince) {
      const foundProvince = provinces?.find(
        province => province.provinceName === defaultProvince
      );
      setValue("province", [
        foundProvince?.id ?? "",
        foundProvince?.provinceName ?? ""
      ]);
      if (!defaultCanton) setValue("canton", foundProvince?.provinceName);
      if (!defaultParish) setValue("parish", foundProvince?.provinceName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultProvince, provinces]);

  return (
    <Styles className={`AddressForm ${className}`}>
      <form onSubmit={handleSubmit(submitHandler)}>
        {renderProvinces}
        <TextInput
          {...register("mainStreet")}
          className="AddressForm__text-input field"
          label={t.forms.address}
          placeholder={t.forms.address}
          errorMessage={mainStreet?.message}
          maxLength={50}
        />
        <TextInput
          {...register("secondaryStreet")}
          className="AddressForm__text-input field"
          label={t.forms.secondaryAddress}
          placeholder={t.forms.secondaryAddress}
          errorMessage={secondaryStreet?.message}
          maxLength={50}
        />

        <Controller
          name="numberContactAddress"
          control={control}
          defaultValue={getValues().numberContactAddress}
          render={({ field }) => (
            <PhoneNumberInput
              {...field}
              className="AddressForm__text-input  field"
              label={t.profile.profileBilling.modal.phone}
              countriesCodes={countryISOCodes}
              onChange={e => {
                setPhone(e);
                field.onChange(e.national);
              }}
              placeholder="099 999 9999"
              errorMessage={numberContactAddress?.message}
              inputValue={phone.national}
              required
              id={"numberContactAddress"}
              maxLength={12}
            />
          )}
        />

        <TextInput
          {...register("reference")}
          className="AddressForm__text-input  field"
          label={t.forms.reference}
          placeholder={t.forms.referencePlaceholder}
          errorMessage={reference?.message}
          maxLength={50}
        />
        <Divider className="AddressForm__divider" />
        <div className="AddressForm__buttonGroup">
          <div className="AddressForm__buttonGroup__title">
            {t.common.label}
          </div>
          <div className="AddressForm__buttonGroup__options">
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Local"
                className="AddressForm__option__radio"
              />
              <span className="AddressForm__option__button">
                <LocalSVG />
                {t.common.local}
              </span>
            </label>
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Bodega"
                className="AddressForm__option__radio"
              />
              <span className="AddressForm__option__button">
                <WinerySVG />
                {t.common.winery}
              </span>
            </label>
            <label className="AddressForm__option">
              <input
                {...register("nickname")}
                type="radio"
                value="Otro"
                className="AddressForm__option__radio"
              />
              <span className="AddressForm__option__button">
                <HeartLineSVG />
                {t.common.other}
              </span>
            </label>
          </div>
          <TextInput
            {...register("otherNickname")}
            className={`AddressForm__nickname-input ${showInput} field `}
            placeholder="Ej: Mi Casa"
            errorMessage={nicknameErrorMessage}
            maxLength={50}
          />
          {method === "POST" && addressList?.length !== 0 && !isAnonymous ? (
            <Checkbox
              {...register("default")}
              label="Usar como predeterminada"
              name="default"
              className="AddressForm__checkbox field"
            />
          ) : null}
        </div>
        <Divider className="AddressForm__divider" />
        <CommentBox
          className="AddressForm__comment"
          label={t.forms.noteLabel}
          value={comment}
          placeholder={t.forms.notePlaceholder}
          rows={3}
          onChange={e => setComment(e.target.value)}
          name="addressForm-comment"
        />
        {errorMessage ? (
          <p className="AddressForm__error-message">{errorMessage}</p>
        ) : null}
        <Button
          className="AddressForm__button"
          isLoading={isLoadingPost || isLoadingPut}
          htmlType="submit"
          disabled={disabled}
        >
          {t.forms.saveAddress}
        </Button>
      </form>
    </Styles>
  );
};

export default AddressForm;
