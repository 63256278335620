import styled from "styled-components";

import { SuccessNotificationStyledProps as Props } from "./SuccessNotification.types";
import Notification from "components/global/notifications/Notification/Notification";
import { getMaxWidth } from "utils/styling.utils";

const SuccessNotificationStyled = styled(Notification)<Props>`
  display: flex;
  padding: 1.6rem;
  align-items: center;
  border: 0.1rem solid var(--palette-green-s78-l89);
  background-color: var(--palette-green-s78-l89);
  max-width: 100%;
  width: calc(${getMaxWidth()} - 40rem);
  justify-content: center;
  flex-direction: column;

  .SuccessNotification {
    &__title {
      color: var(--palette-green-s63-l42);
      font-weight: 600;
      line-height: 2rem;
      font-size: 1.6rem;
      text-align: center;
    }

    &__message {
      font-size: 1.4rem;
      color: var(--palette-green-s63-l42);
      font-weight: 400;
      line-height: 2rem;
    }
  }
`;

export default SuccessNotificationStyled;
