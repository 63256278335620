import styled from "styled-components";

import { AddNewRecordButtonStyledProps as Props } from "./AddNewRecordButton.types";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { tablet } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const AddNewRecordButtonStyled = styled(Button)<Props>`
  font-family: ${primary};

  &&.AddNewRecordButton {
    background-color: var(--palette-primary-light);
    border-radius: 10rem;
    padding: 0.8rem 1.6rem;
    border: 0.1rem solid var(--palette-primary);
    min-height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${tablet}px) {
      width: 100%;
    }

    path {
      fill: var(--palette-primary);
    }

    &:hover:not([disabled]) {
      transition: 0.8s ease;

      path {
        fill: ${props =>
          props.color === "primary-light"
            ? "var(--palette-primary)"
            : "var(--palette-white)"};
      }

      .AddNewRecordButton__value {
        color: ${props =>
          props.color === "primary-light"
            ? "var(--palette-primary)"
            : "var(--palette-white)"};
      }
    }
  }

  .AddNewRecordButton {
    &__value {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;
      color: var(--palette-primary);

      @media (max-width: ${tablet}px) {
        font-size: 1.4rem;
        line-height: inherit;
      }
    }
  }
`;

export default AddNewRecordButtonStyled;
