import { useFetchUser } from "@simple/services";
import { useRouter } from "next/router";
import { useEffect } from "react";

import useAuth from "contexts/auth/auth.context.hooks";

const useListenUser = () => {
  const { push, asPath } = useRouter();
  const authContext = useAuth();
  const { isAnonymous, uid } = authContext;
  const { data: user, error } = useFetchUser(authContext);
  const { message } = error ?? {};

  // Redirect user depending on how complete is its registered process
  useEffect(() => {
    if (!isAnonymous && user) return;

    if (
      asPath === "/sign-in-ruc" ||
      asPath === "/signin" ||
      asPath.includes("/signin-magic") ||
      asPath.includes("redirect") ||
      asPath.includes("recover") ||
      asPath.includes("/magic-link")
    ) {
      return;
    }
    if (message === "No user for uid") {
      push(`/signup-user/?redirect=${asPath ?? "/"}`);
      return;
    }
    if (isAnonymous && !user) {
      push("/sign-in-ruc");
    }
  }, [message, push, isAnonymous, uid, user, asPath]);

  // useEffect(() => {
  //   if (!currentUser) return;
  //   setProviderData(currentUser.providerData);
  // }, [currentUser, setProviderData]);
};

export default useListenUser;
