import styled from "styled-components";

import { LayoutStyledProps as Props } from "./Layout.types";

const LayoutStyled = styled.div<Props>`
  width: 100%;
  isolation: isolate;
  background-color: var(--palette-gray-s0-l93);

  .Layout {
  }
`;

export default LayoutStyled;
