import { getProductTotals } from "artisn/products";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { CartProduct, ShoppingCart } from "artisn/types";
import { CartProductQuestion, OrderDetails, OrderProduct } from "artisn/types";

/**
 * Utility to get the subtotal of the shoppingCart when benefit of type discount is applied
 *
 * @since 3.0.0
 * @param {ShoppingCart}shoppingCart
 * @returns {number | null}
 */
export const getProductsSubtotalUtility = (shoppingCart?: ShoppingCart) => {
  if (!shoppingCart) return null;
  const { benefits } = shoppingCart;

  if (!benefits) return null;

  if (!benefits.length) return null;

  const [benefit] = benefits;
  const { type } = benefit;

  if (type === "ALTER_DELIVERY" || type === "PRODUCT") return null;

  const products = getShoppingCartProducts(shoppingCart);
  const subtotal = products.reduce((prev, current) => {
    const { amount } = current;
    const { grossPrice = 0 } = getProductTotals(current, amount);
    return prev + grossPrice;
    // return prev + netPrice;
  }, 0);

  return subtotal;
};

/**
 * Get shopping cart shipping cost.
 *
 * @since 3.0.1
 * @param {ShoppingCart} shoppingCart Current shopping cart
 * @returns {number} Shopping cart shipping cost
 */
export const getCartShippingCost = (shoppingCart?: ShoppingCart) => {
  if (!shoppingCart) return undefined;
  const { shippingCost } = shoppingCart;
  if (!shippingCost) return undefined;
  // @ts-ignore TODO: add type to sdk
  const { total, discountNetPrice } = shippingCost;
  return total + Number(discountNetPrice ?? 0);
};

/**
 * Get discount value from shopping cart when a benefit is applied.
 *
 * @since 3.0.1
 * @param {ShoppingCart} shoppingCart Current shopping cart
 * @returns {number} Discount total
 */
export const getDiscountTotalUtility = (
  shoppingCart?: ShoppingCart
): number => {
  if (!shoppingCart) return 0;

  const { benefits } = shoppingCart;
  const [benefit] = benefits ?? [];
  if (!benefit) return 0;

  const subTotal = getProductsSubtotalUtility(shoppingCart);
  const shippingCostTotal = getCartShippingCost(shoppingCart);
  const { discountFixed, type } = benefit;
  const discountPercentage = (benefit?.discountPercentage ?? 0) * 0.01;

  switch (type) {
    case "PRODUCT":
      return 0;
    case "ALTER_DELIVERY":
      return shippingCostTotal ?? 0;
    case "DISCOUNT_FIXED":
      return Number(discountFixed ?? 0);
    case "DISCOUNT_PERCENTAGE":
      return (subTotal ?? 0) * discountPercentage;
    default:
      return 0;
  }
};

/**
 * Retrieves the modifiers utility for a given set of questions and answers and product amount.
 * @param questionsAndAnswers - An array of CartProductQuestion objects representing the questions and answers.
 * @param productAmount - The amount of the product.
 * @returns An array of modifier objects containing the amount, name, and price.
 */
export const getModifiersUtility = (
  questionsAndAnswers: CartProductQuestion[],
  productAmount: number
) => {
  const modifiers: any[] = [];

  questionsAndAnswers?.forEach(question => {
    const { answers } = question;
    answers?.forEach(answer => {
      // @ts-ignore
      const { billAnswer, productName, additionalInfo } = answer;

      const { amount } = billAnswer;
      // @ts-ignore
      const { prices } = additionalInfo;
      const { gross_price } = prices.normal;
      if (gross_price) {
        modifiers.push({
          amount: amount / productAmount,
          name: productName,
          price: gross_price
        });
      }
    });
  });

  return modifiers;
};

/**
 * Retrieves the modifiers utility for a given set of questions and answers and product amount.
 * @param questionsAndAnswers - An array of CartProductQuestion objects representing the questions and answers.
 * @param productAmount - The amount of the product.
 * @returns An array of modifier objects containing the amount, name, and price.
 */
export const getShoppingCartModifiersUtility = (
  questionsAndAnswers: CartProductQuestion[],
  productAmount: number
) => {
  const modifiers: any[] = [];

  questionsAndAnswers?.forEach(question => {
    const { answers } = question;
    answers?.forEach(answer => {
      const { prices, amount, name } = answer;
      const { NORMAL } = prices ?? {};
      const { grossPrice } = NORMAL;
      if (grossPrice) {
        modifiers.push({
          amount: amount / productAmount,
          name: name,
          price: grossPrice
        });
      }
    });
  });

  return modifiers;
};

/**
 * Retrieves the unit price from the given order product.
 * @param orderProduct - The order product object.
 * @returns The gross unit price of the order product.
 */
export const getUnitPriceUtility = (orderProduct: OrderProduct) => {
  const { additionalInfo } = orderProduct;
  const { prices } = additionalInfo;
  const { normal } = prices ?? {};
  const { gross_price } = normal ?? {};
  return gross_price;
};

/**
 * Calculates the subtotal from a shopping cart.
 *
 * @param shoppingCart - The shopping cart object.
 * @returns The subtotal of the shopping cart.
 */
export const getSubtotalFromShoppingCart = (shoppingCart?: ShoppingCart) => {
  if (!shoppingCart) return 0;
  const products = getShoppingCartProducts(shoppingCart);

  const subTotal = products.reduce(
    (previousValue: number, product: CartProduct) => {
      const { questionsAndAnswers, prices, amount } = product;
      const { NORMAL } = prices ?? {};
      const { grossPrice } = NORMAL ?? {};
      if (!amount) return 0;

      const modifiersList = getShoppingCartModifiersUtility(
        questionsAndAnswers as CartProductQuestion[],
        amount
      );
      const modifiersTotal = modifiersList.reduce(
        (previousValue, currentModifier) => {
          return previousValue + currentModifier.price * currentModifier.amount;
        },
        0
      );
      return previousValue + (grossPrice + modifiersTotal) * amount;
    },
    0
  );
  return subTotal;
};

/**
 * Calculates the subtotal from the order details.
 *
 * @param order - The order details.
 * @returns The subtotal of the order.
 */
export const getSubtotalFromOrderDetails = (order?: OrderDetails) => {
  if (!order) return 0;
  const { shoppingCart } = order;
  const { stores } = shoppingCart;
  // @ts-ignore TODO: stores is an array, update SDK
  const [{ products }] = stores ?? [{}];

  const normalProducts = products.filter(
    (product: any) => product.additionalInfo.price_category === "NORMAL"
  );

  const subTotal = normalProducts.reduce(
    (previousValue: number, product: OrderProduct) => {
      const { questionsAndAnswers, billProduct } = product;
      const { amount } = billProduct;
      const modifiersList = getModifiersUtility(
        questionsAndAnswers as CartProductQuestion[],
        amount
      );
      const modifiersTotal = modifiersList.reduce(
        (previousValue, currentModifier) => {
          return previousValue + currentModifier.price * currentModifier.amount;
        },
        0
      );
      const unitPrice = getUnitPriceUtility(product);
      return previousValue + (unitPrice + modifiersTotal) * amount;
    },
    0
  );
  return subTotal;
};
