import { lighten, darken } from "polished";

const primary = "hsla(19, 89%, 54%, 1)";

const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  "primary-dark": darken(0.12, primary),
  "primary-light": lighten(0.47, primary),
  // This variable is for capacitor use only, it represents the primary color.
  "primary-hex": "#4811d4",
  "purple-s85-l45": primary,
  "purplet-s48-l39": "hsla(269, 48%, 39%, 1)",
  "purplet-s48-l34": "hsla(271, 48%, 34%, 1)",
  "purple-s72-l92": "hsla(257, 72%, 92%, 1)",
  primary: "hsla(19, 89%, 54%, 1)",
  "green-s100-l40": "hsla(88, 100%, 40%, 1)",
  "green-s63-l42": "hsla(145, 63%, 42%, 1)",
  "green-s78-l89": "hsla(145, 78%, 89%, 1)",
  "green-s90-l35": "hsla(176, 90%, 35%, 1)",
  "red-s61-l87": "hsla(0, 61%, 87%, 1)",
  "red-s60-l60": "hsla(2, 60%, 60%, 1)",
  "blue-s89-l52": "hsla(214, 89%, 52%, 1)",
  "yellow-s100-l55": "hsla(50, 100%, 54%, 1)",
  "yellow-s100-l50": "hsla(57, 100%, 50%, 1)",
  "orange-s100-l98": "hsla(18, 100%, 98%, 1)",
  "orange-s92-l95": "hsla(18, 92%, 95%, 1)",
  "orange-h10-s89-l54": "hsla(10, 89%, 54%, 1)",
  "orange-s89-l54": "hsla(19, 89%, 54%, 1)",
  "orange-s89-l49": "hsla(21, 89%, 49%, 1)",
  // This variable is for capacitor use only, it represents the black color.
  "black-hex": "#000000",
  black: "hsla(0, 0%, 0%, 1)",
  "black-s0-l0-a01": "hsla(0, 0%, 0%, 0.1)",
  "black-s0-l10": "hsla(0, 0%, 10%, 1)",
  "gray-s0-l20-a04": "hsla(0, 0%, 20%, 0.04)",
  "gray-s0-l20-a08": "hsla(0, 0%, 20%, 0.08)",
  "gray-s0-l20-a16": "hsla(0, 0%, 20%, 0.16)",
  "gray-s0-l20-a20": "hsla(0, 0%, 20%, 0.20)",
  "gray-s0-l20-a70": "hsla(0, 0%, 20%, 0.70)",
  "gray-s0-l20": "hsla(0, 0%, 20%, 1)",
  "gray-s0-l30": "hsla(0, 0%, 30%, 1)",
  "gray-s0-l25": "hsla(0, 0%, 25%, 1)",
  "gray-s0-l35": "hsla(0, 0%, 35%, 1)",
  "gray-s0-l40-a40": "hsla(0, 0%, 40%, 0.4)",
  "gray-s0-l40": "hsla(0, 0%, 40%, 1)",
  "gray-s0-l70-a48": "hsla(0, 0%, 70%, 0.48)",
  "gray-s0-l70": "hsla(0, 0%, 70%, 1)",
  "gray-s0-l80": "hsla(0, 0%, 80%, 1)",
  "gray-s0-l85": "hsla(0, 0%, 85%, 1)",
  "gray-s0-l90": "hsla(0, 0%, 90%, 1)",
  "gray-s0-l92": "hsla(0, 0%, 92%, 1)",
  "gray-s0-l93": "hsla(0, 0%, 93%, 1)",
  "gray-s0-l95": "hsla(0, 0%, 95%, 1)",
  "gray-s0-l98": "hsla(0, 0%, 98%, 1)",
  "gray-s4-l91": "hsla(210, 4%, 91%, 1)",
  "white-s0-l100-a37": "hsla(0, 0%, 100%, 0.376)",
  // This variable is for capacitor use only, it represents the white color.
  "white-hex": "#ffffff",
  white: "hsla(0, 0%, 100%, 1)",
  text: {
    primary: "var(--palette-primary)",
    white: "var(--palette-white)"
  },
  error: "hsl(359, 100%, 65%)"
};

const fonts = {
  primary: "'Montserrat', sans-serif",
  outService: "'Passion One', sans-serif"
};

const sizes = {
  page: {
    minWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    maxWidth:
      "calc(100vw - (100vw - 100%) - env(safe-area-inset-left) - env(safe-area-inset-right))",
    minHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    maxHeight:
      "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
    columns:
      "[fullBleed-start] minmax(1.6rem, 1fr) [content-start] minmax(0, 120rem) [content-end] minmax(1.6rem, 1fr) [fullBleed-end]"
  },
  navbar: {
    height: "calc(9rem + env(safe-area-inset-top))",
    "height-mobile": "calc(12rem + env(safe-area-inset-top))",
    "height-mobile-without-search": "calc(5.8rem + env(safe-area-inset-top))"
  }
};

const variables = { palette, fonts, sizes };

export default variables;
