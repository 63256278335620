import styled from "styled-components";

import { SearchAddressStyledProps as Props } from "./SearchAddress.types";
import { SearchAddressContentStyledProps } from "./SearchAddress.types";

const SearchAddressStyled = styled.div<Props>`
  display: flex;
  padding: 0.8rem 1.4rem;
  background-color: var(--palette-white);

  .SearchAddress {
    &__search-bar {
      width: 100%;
    }

    &__loading {
      height: 2rem;
      width: 2rem;
      animation: rotate 0.6s linear infinite;
    }

    &__cancel-button {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  &&& .SearchBar {
    &__wrapper {
      background-color: var(--palette-gray-s0-l95);
    }

    &__input {
      padding: 1rem 1.2rem;
      border: none;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      color: var(--palette-black-s0-l10);
      background-color: var(--palette-gray-s0-l95);

      &:focus {
        box-shadow: none;
      }
    }

    &__btn-search {
      background-color: var(--palette-gray-s0-l95);
    }
  }
`;

export const SearchAddressContentStyled = styled.div<SearchAddressContentStyledProps>`
  position: fixed;
  width: inherit;
  max-height: calc(100vh - 15.6rem);
  overflow: auto;
  padding-bottom: 0.8rem;
  background-color: var(--palette-white);

  @media (hover: none) {
    width: var(100vw - 3.2rem);
  }

  .SearchAddressContent {
    &__address-info {
      padding: 0.8rem 0;
      cursor: pointer;

      .InfoCard {
        &__content {
          display: flex;
          flex-direction: column-reverse;
        }
      }

      &:hover {
        background-color: var(--palette-gray-s0-l98);
      }
    }

    &__empty {
      font-size: 1.6rem;
      padding: 1.6rem 0;
      padding-left: 1.2rem;
    }
  }
`;

export default SearchAddressStyled;
