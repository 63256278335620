import styled, { css } from "styled-components";

import { StoreMarkerStyledProps as Props } from "./StoreMarker.types";

export const storeStylesByStatus = (props: Props) => {
  const { isActive } = props;

  if (isActive) {
    return css`
      background-color: var(--palette-primary);
    `;
  }

  return css`
    background-color: var(--palette-white);
    border: 0.1rem solid var(--palette-primary);
  `;
};

const StoreMarkerStyled = styled.div<Props>`
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.8rem 1.46rem rgba(0, 0, 0, 0.2);
  ${storeStylesByStatus}

  .StoreMarker {
  }
`;

export default StoreMarkerStyled;
