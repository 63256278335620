// Initialize ShoppingCart
import { useStores } from "@simple/contexts";
import { useShoppingCart } from "@simple/contexts";
import { sanitizeQueryParams } from "@simple/utils";
import { checkInit } from "artisn/shopping-cart";
import { closeShoppingCart, initShoppingCart } from "artisn/shopping-cart";
import { listenShoppingCart, listenBenefitsWallet } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

import { artisn, auth } from "config/artisn.config";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { defaultFunction } from "utils/common.utils";

const { ARTISN, FEATURE_FLAGS } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, DEFAULT_VENDOR } = ARTISN;
const { ACCOUNT_ID, SHOPPING_CART_WISHLIST_NAME } = ARTISN;
const { WITH_WISHLIST } = FEATURE_FLAGS;
const { id: vendorId } = DEFAULT_VENDOR;

const useSetupShoppingCart = () => {
  const { query } = useRouter();
  const { setShoppingCart, setWishlist, setBenefitsWallet } = useShoppingCart();
  const { shareId } = sanitizeQueryParams(query);
  const { selectedStore } = useStores();
  const { isAnonymous } = useAuth();
  const customerId = auth()?.currentUser?.uid;
  const unsubscribeCart = useRef<typeof defaultFunction>(defaultFunction);
  const unsubscribeWishlist = useRef<typeof defaultFunction>(defaultFunction);

  const closeHandler = () => {
    if (!checkInit()) return;
    unsubscribeCart.current();
    unsubscribeWishlist.current();
    closeShoppingCart();
  };

  useEffect(() => {
    if (
      !customerId ||
      checkInit() ||
      typeof isAnonymous === "undefined" ||
      !selectedStore
    ) {
      return;
    }
    selectedStore.polygons = null;
    // initialize shopping cart
    initShoppingCart(artisn, {
      accountId: ACCOUNT_ID,
      customerId,
      store: selectedStore
    });

    (async () => {
      // listen default cart changes
      const cart = await listenShoppingCart(cart => setShoppingCart(cart), {
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous
      });
      unsubscribeCart.current = cart;
      if (!WITH_WISHLIST || isAnonymous) return;

      // listen wishlist cart changes
      const wishlist = await listenShoppingCart(cart => setWishlist(cart), {
        shoppingCartName: SHOPPING_CART_WISHLIST_NAME,
        anonymous: isAnonymous
      });
      unsubscribeWishlist.current = wishlist;
    })();

    // start listening to wallet changes
    let unsubscribe: () => void;

    try {
      unsubscribe = listenBenefitsWallet(wallet => setBenefitsWallet(wallet), {
        customerId,
        vendorId,
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous
      });
    } catch (e) {
      console.error(e.message);
    }

    // If rerenders ensures it closes the old shoppingCart
    return () => {
      /*
        TODO: Mejorar closeHandler para que cree una cola de promesas y no se
        cierre mientras no se resuelvan todas pero si bloquee nuevas promesas
      */
      closeHandler();
      unsubscribe();
    };
  }, [
    customerId,
    selectedStore,
    setShoppingCart,
    setWishlist,
    setBenefitsWallet,
    shareId,
    isAnonymous
  ]);
};

export default useSetupShoppingCart;
