import { Modal } from "artisn-ui-react";
import styled from "styled-components";

import { PrivacyModalStyledProps as Props } from "./PrivacyModal.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const PrivacyModalStyled = styled(Modal)<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 2.4rem;

  @media (max-width: ${mobile}px) {
    width: 90%;
  }

  .PrivacyModal {
    &__title {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 3.2rem;
      padding-bottom: 2rem;
    }

    &__card {
      background-color: var(--palette-orange-s92-l95) !important;
    }

    &__textBlack {
      color: var(--palette-black-s0-l10) !important;
    }

    &__checkbox {
      padding-bottom: 1.6rem;
    }

    &__terms {
      font-size: 1.6rem;
      color: var(--palette-gray-s80);
      line-height: 1.6rem;

      & > a {
        color: var(--palette-primary-s80);
        text-decoration: underline;
        font-weight: 500;
      }
    }

    &__error-message {
      margin-bottom: 1.2rem;
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
    }

    &__button {
      width: 90%;
      margin: 0 auto;
      margin-bottom: 1.6rem;
    }
  }
`;

export default PrivacyModalStyled;
