import React from "react";

import DropdownOptions from "../DropdownOptions/DropdownOptions";
import Styles from "./ListItem.styles";
import { ListItemProps as Props } from "./ListItem.types";

import LoadingSVG from "../../../../public/assets/images/loading.svg";
import LocalSVG from "../../../../public/assets/images/local.svg";
import WinerySVG from "../../../../public/assets/images/winery.svg";

const ListItem: React.FC<Props> = props => {
  const { className, onClick, isLoading, title, description } = props;
  const { isDefault, icon, isActive = false, actions } = props;
  const { isFixed, onEdit, onDelete, onDefault } = props;

  const renderIcon = () => {
    if (isLoading) {
      return (
        <div className="ChooseAddress__loading">
          <LoadingSVG viewBox="0 0 66 66" />
        </div>
      );
    }
    const enhancedNickname = title.toUpperCase();
    if (enhancedNickname === "LOCAL") return <LocalSVG />;
    if (enhancedNickname === "BODEGA") return <WinerySVG />;
    return icon;
  };

  return (
    <Styles className={`ListItem ${className}`} isActive={isActive}>
      {renderIcon()}
      <div className="ListItem__content" onClick={onClick}>
        <div className="ListItem__title">
          <p className="ListItem__value">{title}</p>
          {/* TODO: Ask UX team if the selected value will appear as a default value 
          <span className="ListItem__default">
            {isDefault ? " (Predeterminado)" : ""}
          </span> */}
        </div>
        <div className="ListItem__description">{description}</div>
      </div>
      {actions?.length ? (
        <DropdownOptions
          onEdit={actions?.includes("edit") ? onEdit : undefined}
          onDelete={actions?.includes("delete") ? onDelete : undefined}
          onDefault={
            actions?.includes("default") && !isDefault ? onDefault : undefined
          }
          isFixed={isFixed}
        />
      ) : null}
    </Styles>
  );
};

ListItem.defaultProps = {
  className: ""
};

export default ListItem;
