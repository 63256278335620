// AddressForm helper functions and data

import { PutShippingAddressPayload } from "@simple/services";
import { PostShippingAddressPayload } from "@simple/services";
import { LivingPlace, ShippingAddress } from "artisn/types";
import * as yup from "yup";

import { AddressFormValues } from "./AddressForm.types";
import { Country } from "types/country.types";
import { Google } from "types/geo.types";
import { validationRules } from "utils/form.utils";

const { requiredString, requiredPhoneNumber } = validationRules;

export const AddressFormSchema = yup.object().shape({
  mainStreet: requiredString,
  reference: requiredString,
  nickname: requiredString,
  numberContactAddress: requiredPhoneNumber
});

export const getDefaultLivingPlace = (
  additionalInfo?: string,
  comment?: string
): LivingPlace => {
  return {
    id: 1,
    name: "",
    active: 0,
    countryId: 0,
    fields: [
      {
        id: "additional_data",
        // HACK: Sirve para conservar las direcciones resultados de la API de Google
        label: additionalInfo ?? "",
        rules: {
          max: 0,
          min: 0
        },
        messages: {
          max: "0",
          min: "0"
        },
        value: "Local"
      },
      {
        id: "comment",
        label: "comment",
        rules: {
          max: 0,
          min: 0
        },
        messages: {
          max: "0",
          min: "0"
        },
        value: comment ?? ""
      }
    ]
  };
};

export const getSelectedPostPayload = (
  form: AddressFormValues,
  mapAddress: Google.Geocode,
  country: Country,
  comment: string
): PostShippingAddressPayload => {
  const { formatted_address, geometry } = mapAddress ?? {};
  const { location: coordinates } = geometry ?? {};
  const {
    otherNickname,
    nickname,
    mainStreet,
    secondaryStreet,
    numberContactAddress
  } = form;
  const enhancedNickname = nickname === "Otro" ? otherNickname : nickname;
  for (const key in form) {
    if (key !== "otherNickname") continue;
    delete form[key];
    break;
  }

  return {
    ...form,
    ...coordinates,
    livingPlace: getDefaultLivingPlace(formatted_address, comment),
    country: {
      id: country.id,
      name: country.name
    },
    number: "",
    nickname: enhancedNickname!,
    mainStreet,
    addressByGoogle: formatted_address,
    secondaryStreet,
    numberContactAddress: `${numberContactAddress}`,
    additionalInfo: {
      Comment: "note tes save"
    },
    //TODO: check types
    //@ts-ignore
    city: {
      id: 1,
      name: "Guayaquil"
    },
    sector: {
      id: 1,
      name: "Guayaquil"
    },
    zone: {
      id: 1,
      name: "Guayaquil",
      zipCode: "090101"
    }
  };
};

export const getSelectedPutPayload = (
  form: AddressFormValues,
  mapAddress: Google.Geocode,
  country: Country,
  initialValues: AddressFormValues,
  editAddress: ShippingAddress,
  comment: string
): PutShippingAddressPayload => {
  const { formatted_address, geometry } = mapAddress ?? {};
  const { livingPlace } = editAddress ?? {};
  const { location: coordinates } = geometry ?? {};
  const {
    otherNickname,
    nickname,
    mainStreet,
    secondaryStreet,
    numberContactAddress
  } = form;
  const enhancedNickname = nickname === "Otro" ? otherNickname : nickname;
  for (const key in form) {
    if (key !== "otherNickname") continue;
    delete form[key];
    break;
  }

  return {
    ...form,
    ...coordinates,
    livingPlace: mapAddress
      ? getDefaultLivingPlace(formatted_address, comment)
      : {
          ...livingPlace,
          fields: [
            livingPlace.fields[0],
            {
              id: "comment",
              label: "comment",
              rules: {
                max: 0,
                min: 0
              },
              messages: {
                max: "0",
                min: "0"
              },
              value: comment ?? ""
            }
          ]
        },
    country: {
      id: country.id,
      name: country.name
    },
    number: "",
    nickname: enhancedNickname!,
    mainStreet,
    addressByGoogle: formatted_address,
    id: initialValues.id!,
    secondaryStreet,
    numberContactAddress: `${numberContactAddress}`,
    additionalInfo: {
      Comment: "note test saves"
    },
    //TODO: check types
    //@ts-ignore
    city: {
      id: 1,
      name: "Guayaquil"
    },
    sector: {
      id: 1,
      name: "Guayaquil"
    },
    zone: {
      id: 1,
      name: "Guayaquil",
      zipCode: "090101"
    }
  };
};

export const getDefaultNickname = (nickname?: string) => {
  if (!nickname) return "Local";
  if (nickname !== "Local" && nickname !== "Bodega") return "Otro";
  return nickname;
};
