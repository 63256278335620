import styled from "styled-components";

import { AddressFormStyledProps as Props } from "./AddressForm.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;
const { primary } = variables.fonts;

const AddressFormStyled = styled.div<Props>`
  font-family: ${primary};
  padding-top: 1.6rem;
  padding-bottom: 3.2rem;
  background-color: var(--palette-white);

  .CommentBox {
    &__label {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.6rem;
      color: var(--palette-black-s0-l10);
    }

    &__textarea {
      border: 0.1rem solid var(--palette-gray-s0-l70);
      border-radius: 1.6rem;
    }
  }

  .AddressForm {
    &__text-input {
      padding-bottom: 2.4rem;
    }

    &__selectLabel {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.8rem;
      margin-left: 0.8rem;
      color: var(--palette-gray-s0-l40);
      opacity: 0.8;
    }

    &__selectItem {
      margin-bottom: 1.6rem;
    }

    &__selectContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--select {
        color: var(--palette-gray-s0-l40);
        width: 100%;
        border-radius: 2.4rem;
        padding: 1.6rem;
        border: 0.1rem solid var(--palette-gray-s0-l70);
        appearance: none;
        background: white url("/assets/images/chevron-down.svg") no-repeat right
          1.6rem center;
        background-size: 1rem;

        &:hover,
        &:focus {
          border: 0.1rem solid var(--palette-primary) !important;
        }
      }
    }

    &__divider {
      height: 0.8rem;
      margin: 1.6rem 0;
    }

    &__buttonGroup {
      &__title {
        color: var(--palette-black-s0-l10);
        padding-bottom: 0.8rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 500;
      }

      &__options {
        display: flex;
      }
    }

    &__option {
      &:not(:first-of-type) {
        padding-left: 0.8rem;
      }

      &__radio {
        display: none;

        &:checked + .AddressForm__option__button {
          border-color: var(--palette-primary);
        }
      }

      &__button {
        display: inline-flex;
        cursor: pointer;
        height: 4.8rem;
        width: 14.4rem;
        align-items: center;
        padding: 1.6rem 2.4rem;
        border: 0.2rem solid var(--palette-black-s0-l10);
        border-radius: 10rem;
        text-align: center;
        font-weight: 600;
        color: var(--palette-black-s0-l10);
        font-size: 1.6rem;
        line-height: 2rem;

        @media (max-width: ${mobile}px) {
          width: 100%;
        }

        svg {
          margin-right: 0.8rem;

          path {
            fill: var(--palette-black-s0-l10);
          }
        }
      }
    }

    &__nickname-input {
      display: none;
      padding-top: 2.4rem;
      width: 100%;

      .TextInput__input {
        width: 100%;
      }

      &--show {
        display: block;
      }
    }

    &__checkbox {
      padding-top: 3.2rem;
    }

    &__comment {
      padding-bottom: 2.4rem;
    }

    &__error-message {
      color: var(--palette-red-s60-l60);
      font-size: 1.2rem;
      padding-bottom: 2.4rem;

      &-pl {
        padding-left: 1rem;
      }
    }

    &__button {
      width: 100%;
      padding: 1.2rem;
      margin-top: 0.8rem;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;

export default AddressFormStyled;
