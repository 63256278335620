import { useCountries } from "@simple/contexts";
import { useFetchUser, usePutUser } from "@simple/services";
import { usePutUserSettings } from "@simple/services";
import { useFetchCurrentPrivacyPolicies } from "@simple/services";
import { events } from "artisn/analytics";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import Button from "../Button/Button";
import Styles from "./PrivacyModal.styles";
import { PrivacyModalProps as Props } from "./PrivacyModal.types";
import { TermsModalFormValues } from "./PrivacyModal.types";
import PrivacyPoliciesWithCheckboxes from "components/signUpUser/SignUpUser/PrivacyPoliciesWithCheckboxes/PrivacyPoliciesWithCheckboxes";
import TermsWithCollapseText from "components/signUpUser/SignUpUser/TermsWithCollapseText/TermsWithCollapseText";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import { changeTimezone } from "utils/date.utils";
import { checkIfUserHasReadPolicies } from "utils/user.utils";
import { createUserPrivacyPoliciesIndex } from "utils/user.utils";

const { logCustomEvent } = events;
const { logUpdateUserInfo } = events.user;

const { NOTIFICATION_NAME } = CONSTANTS;

const PrivacyModal: React.FC<Props> = props => {
  const auth = useAuth();
  const privacyPoliciesQuery = useFetchCurrentPrivacyPolicies(auth);
  const fetchUserQuery = useFetchUser(auth);
  const putUserQuery = usePutUser(auth);
  const putUserSettingsQuery = usePutUserSettings(auth);
  const { selectedCountry } = useCountries();
  const t = useI18n();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);

  const signUpUserFormMethods = useForm<TermsModalFormValues>({
    mode: "onChange"
  });

  const { setValue, handleSubmit } = signUpUserFormMethods;

  const { data: user, isSuccess: isSuccessUser } = fetchUserQuery;
  const { mutate: updateUser, reset: resetPutUser } = putUserQuery;
  const { reset: resetUserSettings } = putUserSettingsQuery;
  const { mutateAsync: updateUserSettings } = putUserSettingsQuery;
  const { uid, isAnonymous } = auth;

  const { privacyPolicies: userPrivacyPolicies } = user ?? {};
  const { data: currentPrivacyPolicies } = privacyPoliciesQuery;
  const { isSuccess: isSuccessCurrentPrivacyPolicies } = privacyPoliciesQuery;

  const userHasReadPolicies = useMemo(
    () =>
      checkIfUserHasReadPolicies(userPrivacyPolicies, currentPrivacyPolicies),
    [userPrivacyPolicies, currentPrivacyPolicies]
  );

  const { privacyAcceptDate } = user ?? {};

  const showPrivacy =
    !isAnonymous &&
    isSuccessUser &&
    isSuccessCurrentPrivacyPolicies &&
    user &&
    (!userHasReadPolicies || !privacyAcceptDate);

  const handleClick = (form: TermsModalFormValues) => {
    setError("");
    setIsLoading(true);

    const { privacyPolicies } = form ?? {};

    try {
      if (!uid) {
        throw new Error("No se pudo obtener el id del usuario");
      }

      if (!user) {
        throw new Error("No se pudo obtener el usuario");
      }
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
      return;
    }

    resetPutUser();
    resetUserSettings();

    logCustomEvent("update_privacy_policies");

    const updatedUser = {
      ...user,
      privacyAcceptDate:
        privacyAcceptDate ?? changeTimezone(new Date()).toISOString(),
      uid,
      active: !!user.active,
      country: { id: selectedCountry.id },
      privacyPolicies: Object.values(privacyPolicies)
    };

    updateUser(updatedUser, {
      onSuccess: async () => {
        logUpdateUserInfo({
          userId: uid,
          fields: ["privacyPolicies"]
        });

        try {
          const notificationPrivacyPolicy = currentPrivacyPolicies?.find(
            policy => policy.name === NOTIFICATION_NAME
          )?.id;

          await updateUserSettings({
            uid,
            settings: {
              emailNotifications: notificationPrivacyPolicy
                ? privacyPolicies?.[notificationPrivacyPolicy]?.isActive
                : false,
              pushNotifications: false
            }
          });
          setIsLoading(false);
          setPrivacyModalVisible(false);
        } catch {
          setError("No se pudo actualizar las preferencias de notificación");
        }
      },
      onError: () => {
        setError("No se pudo actualizar el usuario");
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!showPrivacy) return;
    setPrivacyModalVisible(true);
  }, [showPrivacy]);

  useEffect(() => {
    if (!isSuccessCurrentPrivacyPolicies) return;

    const initialValues = createUserPrivacyPoliciesIndex(
      userPrivacyPolicies,
      currentPrivacyPolicies
    );
    setValue("privacyPolicies", initialValues);
  }, [
    currentPrivacyPolicies,
    isSuccessCurrentPrivacyPolicies,
    setValue,
    userPrivacyPolicies
  ]);

  return (
    <Styles
      className="PrivacyModal"
      opened={privacyModalVisible}
      closeOnClickOutside={false}
    >
      <h3 className="PrivacyModal__title">{t.privacyModal.title}</h3>

      <FormProvider {...signUpUserFormMethods}>
        <TermsWithCollapseText
          hideCheckboxTerm
          styleCardPolicies="PrivacyModal__card"
        />
        <PrivacyPoliciesWithCheckboxes textStyle="PrivacyModal__textBlack" />
      </FormProvider>

      <p className="PrivacyModal__error-message">{error}</p>
      <Button
        className="PrivacyModal__button"
        onClick={handleSubmit(handleClick)}
        disabled={
          isLoading || !isSuccessUser || !isSuccessCurrentPrivacyPolicies
        }
      >
        {t.common.accept}
      </Button>
    </Styles>
  );
};

PrivacyModal.defaultProps = {};

export default PrivacyModal;
