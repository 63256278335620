import { useCatalogues } from "@simple/contexts";
import { useStores } from "@simple/contexts";
import { Notification } from "artisn-ui-react";
import React, { useCallback, useEffect, useState } from "react";

import Styles from "./ShoppingCartNotifications.styles";
import { ShoppingCartNotificationsProps as Props } from "./ShoppingCartNotifications.types";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import ChooseStoreModal from "components/chooseStore/ChooseStoreModal/ChooseStoreModal";
import { dismissNoCoverageNotification } from "utils/notifications.utils";
import { createClosedStoreNotification } from "utils/notifications.utils";
import { dismissClosedStoreNotification } from "utils/notifications.utils";
import { createNoCoverageNotification } from "utils/notifications.utils";
import { createInfoNotification } from "utils/notifications.utils";
import { dismissInfoNotification } from "utils/notifications.utils";

const ShoppingCartNotifications: React.FC<Props> = props => {
  const { showClosedStore, showNoCoverage, showMapCoordinates } = props;
  const { setShowNoCoverage, setClosedStore } = props;
  const { setShowMapCoordinates } = props;
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openChooseStore, setOpenChooseStore] = useState(false);
  const { selectedCatalogue } = useCatalogues();
  const { name } = selectedCatalogue ?? {};
  const selectedCatalogueName = name.toUpperCase();
  const { setShowStoreNotification } = useStores();

  const showAddressModal = useCallback(() => {
    setOpenAddressModal(true);
    setShowStoreNotification(true);
  }, [setShowStoreNotification]);

  const showStoreModal = useCallback(() => {
    if (selectedCatalogueName === "DELIVERY") {
      setOpenAddressModal(true);
    } else {
      setOpenChooseStore(true);
    }
    setShowStoreNotification(true);
  }, [selectedCatalogueName, setShowStoreNotification]);

  const closeNoCoverageNotification = useCallback(() => {
    setShowNoCoverage(false);
  }, [setShowNoCoverage]);

  const closeStoreNotification = useCallback(() => {
    setClosedStore(false);
  }, [setClosedStore]);

  const closeInfoNotification = useCallback(() => {
    setShowMapCoordinates(false);
  }, [setShowMapCoordinates]);

  useEffect(() => {
    if (showNoCoverage) {
      createNoCoverageNotification(
        showAddressModal,
        openAddressModal,
        closeNoCoverageNotification
      );
    } else {
      dismissNoCoverageNotification();
    }
    return () => Notification.destroy();
  }, [
    showNoCoverage,
    openAddressModal,
    closeNoCoverageNotification,
    showAddressModal
  ]);

  useEffect(() => {
    if (showClosedStore) {
      createClosedStoreNotification(
        selectedCatalogueName,
        showStoreModal,
        openChooseStore || openAddressModal,
        closeStoreNotification
      );
    } else {
      dismissClosedStoreNotification();
    }
    return () => Notification.destroy();
  }, [
    showClosedStore,
    showStoreModal,
    openChooseStore,
    openAddressModal,
    selectedCatalogueName,
    closeStoreNotification
  ]);

  useEffect(() => {
    if (showMapCoordinates) {
      createInfoNotification(
        "Faltan permisos de ubicación",
        "Debes habilitar los permisos de ubicación para avanzar",
        "Cambiar de ubicación",
        "Cambiar",
        showAddressModal,
        true,
        closeInfoNotification
      );
    } else {
      dismissInfoNotification();
    }
    return () => Notification.destroy();
  }, [showMapCoordinates, closeInfoNotification, showAddressModal]);

  return (
    <Styles className="ShoppingCartNotifications">
      <ShippingAddressModal
        opened={openAddressModal}
        onClose={() => {
          setShowMapCoordinates(false);
          setOpenAddressModal(false);
          setShowStoreNotification(false);
        }}
        locationOnly
        onDismiss={() => closeNoCoverageNotification()}
      />
      <ChooseStoreModal
        opened={openChooseStore}
        onClose={() => {
          setOpenChooseStore(false);
          setShowStoreNotification(false);
        }}
      />
    </Styles>
  );
};

ShoppingCartNotifications.defaultProps = {};

export default ShoppingCartNotifications;
