import styled from "styled-components";

import { ChooseAddressInMapStyledProps as Props } from "./ChooseAddressInMap.types";
import { getMaxHeight } from "utils/styling.utils";

const ChooseAddressInMapStyled = styled.div<Props>`
  .ChooseAddressInMap {
    &__map {
      height: calc(min(${getMaxHeight()}, 72rem) - 10.8rem);
    }
  }
`;

export default ChooseAddressInMapStyled;
