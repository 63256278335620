import { Storage } from "@capacitor/storage";
import { useCatalogues } from "@simple/contexts";
import { useShoppingCart } from "@simple/contexts";
import { sanitizeQueryParams } from "@simple/utils";
import { checkInit } from "artisn/shopping-cart";
import { createShoppingCart, getShoppingCart } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingCost from "hooks/useShippingCost";
import { removeQueryParam } from "utils/common.utils";

const { SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;
const { WITH_SHARE_SHOPPING_CART } = CONSTANTS.FEATURE_FLAGS;
const { WITH_ANONYMOUS_SHOPPING_CART_TOKEN } = CONSTANTS.FEATURE_FLAGS;
const { ANONYMOUS_SHOPPING_CART_TOKEN } = CONSTANTS.STORAGE;

const useListenShoppingCart = () => {
  const { query, asPath, replace } = useRouter();
  const { catalogueId } = useCatalogues()?.selectedCatalogue ?? {};
  const { shareId } = sanitizeQueryParams(query);
  const shippingCost = useShippingCost();
  const newPath = useMemo(() => removeQueryParam(asPath, "shareId"), [asPath]);
  const { isAnonymous } = useAuth();
  const { setIsAnonymousCart } = useShoppingCart();

  /**
   * If there is a shareId (query param) and there is no shopping cart created,
   * fetches the shared shopping cart and sets it as initial shopping cart.
   */
  useEffect(() => {
    (async () => {
      if (!WITH_SHARE_SHOPPING_CART || !shareId || !checkInit()) {
        return;
      }
      const shoppingCart = await getShoppingCart({
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous
      });

      if (shoppingCart) return;

      const incomingCart = await getShoppingCart({
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        customerId: shareId,
        anonymous: isAnonymous
      });

      if (!incomingCart) {
        await replace(newPath, undefined, { shallow: true });
        return;
      }

      await createShoppingCart(
        {
          anonymous: isAnonymous
        },
        {
          stores: incomingCart.stores,
          channelId: +catalogueId,
          shippingCost,
          name: SHOPPING_CART_DEFAULT_NAME
        }
      );

      await replace(newPath, undefined, { shallow: true });
    })();
  }, [catalogueId, newPath, replace, shareId, shippingCost, isAnonymous]);

  /**
   * If there is an anonymous cart in storage and a shopping cart has not been created,
   * retrieve the anonymous shopping cart and set it as the initial shopping cart.
   */
  useEffect(() => {
    (async () => {
      const { value: incomingCart } = await Storage.get({
        key: ANONYMOUS_SHOPPING_CART_TOKEN
      });

      if (
        !WITH_ANONYMOUS_SHOPPING_CART_TOKEN ||
        !incomingCart ||
        !checkInit() ||
        isAnonymous
      ) {
        setIsAnonymousCart(false);
        return;
      }

      const shoppingCart = await getShoppingCart({
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous
      });

      if (shoppingCart) return;

      const anonymousShoppingCart = JSON.parse(incomingCart);

      await createShoppingCart(
        {
          anonymous: isAnonymous
        },
        {
          stores: anonymousShoppingCart.stores,
          channelId: +catalogueId,
          shippingCost,
          name: SHOPPING_CART_DEFAULT_NAME
        }
      );

      await Storage.remove({
        key: ANONYMOUS_SHOPPING_CART_TOKEN
      });

      setIsAnonymousCart(true);
    })();
  }, [catalogueId, isAnonymous, shippingCost, setIsAnonymousCart]);
};

export default useListenShoppingCart;
