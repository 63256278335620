// Products utility functions
import { Storage } from "@capacitor/storage";
import { UseProductCardConfig } from "artisn-ui-react";
import { ProductCardData, ProductTotals } from "artisn-ui-react";
import { getProductTotals, ModifiersForm } from "artisn/products";
import { getShoppingCart, validateShoppingCart } from "artisn/shopping-cart";
import { CartProduct, Product, ProductDetails } from "artisn/types";

import CONSTANTS from "config/constants";
import { ProductPreferences } from "types/common.types";

const { ARTISN, API } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { API_URL } = API;

/**
 * This function is used to transform the details of the product because the
 * artisn library does not yet support an undefined array of questions in the
 * answers.
 *
 * This function will be removed when the artisn library supports this
 * functionality
 */
export const normalizeProductDetails = (
  product: ProductDetails
): ProductDetails => {
  const { questions = [] } = product;
  return {
    ...product,
    questions: questions.map(question => {
      const { answers = [] } = question;
      return {
        ...question,
        answers: answers.map(answer => {
          const { questions = [] } = answer;
          return {
            ...answer,
            questions
          };
        })
      };
    })
  };
};

export const validateShoppingCartUtility = async (
  lat: number,
  lng: number,
  isAnonymous: boolean
) => {
  const shoppingCartValidations = await validateShoppingCart({
    apiURL: API_URL,
    latitude: lat,
    longitude: lng,
    anonymous: isAnonymous,
    shoppingCartName: SHOPPING_CART_DEFAULT_NAME
  });
  const { products, stores } = shoppingCartValidations ?? {};
  stores?.forEach((store: { type: any }) => {
    const { type } = store;
    switch (type) {
      case "IS_OPEN":
        throw new Error(
          "Tienda cerrada, intenta volver luego para realizar tus compras"
        );
      case "OUT_OF_COVERAGE":
        throw new Error(
          "Estás fuera de cobertura, intenta cambiar tu ubicación para continuar"
        );
      default:
        throw new Error("Ocurrió un error al validar el carrito");
    }
  });
  if (products?.length) {
    throw new Error("Error al validar los productos del carrito de compras");
  }
  await checkCartBenefitsAlerts(isAnonymous);
};

export const checkCartBenefitsAlerts = async (isAnonymous: boolean) => {
  // Check alerts for benefits
  const shoppingCart = await getShoppingCart({
    shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
    anonymous: isAnonymous
  });
  const { benefits } = shoppingCart ?? {};
  if (benefits) {
    const benefitsHasAlerts = Object.values(benefits).some(benefit => {
      //@ts-ignore Property alerts is not typed in the sdk
      return benefit?.alerts && benefit?.alerts?.length !== 0;
    });

    if (benefitsHasAlerts) {
      throw new Error("Error al validar los beneficios del carrito de compras");
    }
  }
};

export const saveProductPreference = async (
  form: ModifiersForm | undefined,
  userUid: string | undefined
) => {
  if (!form || !userUid) return;
  const { product, renderer } = form ?? {};
  const { productId } = product;

  const productPreferences: ProductPreferences = {};
  const pastPreferences = await getProductPreferences(product, userUid);

  renderer.forEach(question => {
    const { id: modifierGroupId, modifiers } = question;
    modifiers.forEach(answer => {
      const { id: modifierId, amount, type } = answer;
      if (!amount || type !== "RADIO") return undefined;
      // If there already is a preference saved, update it.
      if (pastPreferences[`${modifierGroupId}-${modifierId}`]) {
        pastPreferences[`${modifierGroupId}-${modifierId}`] = {
          modifierId,
          amount,
          type,
          times: pastPreferences[`${modifierGroupId}-${modifierId}`].times + 1
        };
        Storage.set({
          key: `${userUid}-${productId}`,
          value: JSON.stringify({ ...pastPreferences })
        });
        return;
      }
      // If the preference is different, save it for the first time
      productPreferences[`${modifierGroupId}-${modifierId}`] = {
        modifierId,
        amount,
        type,
        times: 1
      };
    });
  });

  Storage.set({
    key: `${userUid}-${productId}`,
    value: JSON.stringify({ ...pastPreferences, ...productPreferences })
  });
};

export const getMostChosenOptionKey = (preferences: ProductPreferences) => {
  const objectKeys = Object.keys(preferences);
  if (objectKeys.length === 0) return;
  return objectKeys.reduce((acc, value) => {
    if (preferences[acc].times > preferences[value].times) return acc;
    return value;
  });
};

export const getProductPreferences = async (
  product: Product,
  userUid: string | undefined
): Promise<ProductPreferences> => {
  const { productId } = product;

  const { value } = await Storage.get({
    key: `${userUid}-${productId}`
  });
  const productPreference = value ? JSON.parse(value) : {};
  return productPreference;
};

export const getSanitizedProductTotals = (
  product: Product,
  config: UseProductCardConfig = {}
): ProductCardData => {
  const { amount, decimals } = config;
  const { prices, available, priceCategory } = product as CartProduct;
  const { NORMAL, POINTS } = prices;
  const productTotals = getProductTotals(product, amount);
  const sanitizedProductTotals = Object.entries(productTotals).reduce(
    (acc, field) => {
      const [key, value] = field;
      if (typeof value === "string" || value === null) {
        return { ...acc, [key]: value };
      }
      return { ...acc, [key]: +(value as number).toFixed(decimals) };
    },
    {} as ProductTotals
  );
  const totals: ProductTotals = {
    ...sanitizedProductTotals,
    symbol: NORMAL.symbol,
    unitPoints: +POINTS?.netPrice.toFixed(decimals),
    points: +(POINTS?.netPrice * productTotals.amount).toFixed(decimals),
    priceCategory
  };

  return { totals, available };
};
