import styled from "styled-components";

import { PrivacyPoliciesWithCheckboxesStyledProps as Props } from "./PrivacyPoliciesWithCheckboxes.types";

const PrivacyPoliciesWithCheckboxesStyled = styled.div<Props>`
  .PrivacyPoliciesWithCheckboxes {
    &__checkbox {
      padding-bottom: 0.8rem;

      &--border {
        border-bottom: 0.1rem solid white;
      }
    }

    &__config {
      text-decoration: underline;
      padding-bottom: 0.8rem;
    }

    &__configWrapper {
      padding-top: 0.8rem;
    }

    &__text {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.6;
      color: var(--palette-white);
      padding-bottom: 0.8rem;
    }
  }
`;

export default PrivacyPoliciesWithCheckboxesStyled;
