import { AppProps as Props } from "next/app";
import Script from "next/script";
import React from "react";
import "artisn-ui-react/main.css";

import Layout from "components/global/Layout/Layout";
import Providers from "containers/Providers/Providers";

const App: React.FC<Props> = props => {
  const { Component, pageProps } = props;

  return (
    <Providers dehydratedState={pageProps.dehydratedState}>
      <Layout>
        <Script
          id="google-tag-manager"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TS6ZS77F');`
          }}
        ></Script>
        <Script
          id="meta-pixel"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '373499305564915');
            fbq('track', 'PageView');
          `
          }}
        ></Script>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          height="1"
          width="1"
          alt="meta-pixel-img"
          className="display:none"
          src="https://www.facebook.com/tr?id=373499305564915&ev=PageView&noscript=1"
        />
        <Component {...pageProps} />
      </Layout>
    </Providers>
  );
};

export default App;
