import { BillingDataProvider, GeoProvider } from "@simple/contexts";
import { CountriesProvider, VendorsProvider } from "@simple/contexts";
import { CataloguesProvider, ShoppingCartProvider } from "@simple/contexts";
import { PaymentsProvider, StoresProvider } from "@simple/contexts";
import { ProductsProvider } from "@simple/contexts";
import { ShippingAddressProvider } from "@simple/contexts";
import React from "react";

import { ArtisnProviderProps as Props } from "./ArtisnProvider.types";
import AnalyticsProvider from "contexts/analytics/analytics.context";
import AuthProvider from "contexts/auth/auth.context";
import { TalkShopProvider } from "contexts/talkShop/talkShop.context";

const ArtisnProvider: React.FC<Props> = props => {
  const { children } = props;

  return (
    <AnalyticsProvider>
      <AuthProvider>
        <CountriesProvider>
          <VendorsProvider>
            <PaymentsProvider>
              <BillingDataProvider>
                <StoresProvider>
                  <ProductsProvider>
                    <GeoProvider>
                      <ShippingAddressProvider>
                        <ShoppingCartProvider>
                          <CataloguesProvider>
                            <TalkShopProvider>{children}</TalkShopProvider>
                          </CataloguesProvider>
                        </ShoppingCartProvider>
                      </ShippingAddressProvider>
                    </GeoProvider>
                  </ProductsProvider>
                </StoresProvider>
              </BillingDataProvider>
            </PaymentsProvider>
          </VendorsProvider>
        </CountriesProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
};

ArtisnProvider.defaultProps = {};

export default ArtisnProvider;
