import styled from "styled-components";

import { DividerStyledProps as Props } from "./Divider.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const DividerStyled = styled.div<Props>`
  background-color: var(--palette-gray-s0-l98);
  height: ${props => (props.showMobile ? "0" : "0.8rem")};

  @media (max-width: ${tablet}px) {
    height: 0.1rem;
    margin: 0 -1.6rem;
  }

  .Divider {
  }
`;

export default DividerStyled;
