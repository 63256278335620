import { css } from "styled-components";

const typography = css`
  @font-face {
    font-family: "Montserrat-bold";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/Montserrat-Bold.otf") format("truetype");
  }

  @font-face {
    font-family: "Montserrat-semibold";
    font-style: normal;
    font-weight: 600;
    src: url("/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Montserrat-medium";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/Montserrat-Medium.ttf") format("truetype");
  }

  @font-face {
    font-family: "Montserrat-regular";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
  }

  body {
    font-family: "Montserrat-regular", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.4rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
  }

  p {
    color: rgba(0, 0, 0, 0.85);
  }
`;

export default typography;
