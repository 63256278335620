import { Storage } from "@capacitor/storage";
import { useShippingAddress } from "@simple/contexts";
import { useDeleteShippingAddress } from "@simple/services";
import { usePutShippingAddress } from "@simple/services";
import { events } from "artisn/analytics";
import React, { useState } from "react";

import Styles, { DeleteAddressModal } from "./ChooseAddress.styles";
import { ChooseAddressProps as Props } from "./ChooseAddress.types";
import ListItem from "components/global/ListItem/ListItem";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { defaultFunction } from "utils/common.utils";
import { trimFields } from "utils/form.utils";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

import HeartSVG from "../../../../public/assets/images/heart-line.svg";
import TrashSVG from "../../../../public/assets/images/trash.svg";

const { logSelectShippingAddress } = events.shipping;
const { SHIPPING_ADDRESS_TOKEN } = CONSTANTS.STORAGE;

const ChooseAddress: React.FC<Props> = props => {
  const auth = useAuth();
  const { shippingAddress, actions, onClick = defaultFunction } = props;
  const { onEdit: onPressEdit = defaultFunction, dropdownFixed } = props;
  const { mainStreet, secondaryStreet, number } = shippingAddress;
  const { nickname, id, default: isDefault } = shippingAddress;
  const { selectedShippingAddress } = useShippingAddress();
  const { setSelectedShippingAddress } = useShippingAddress();
  const putState = usePutShippingAddress(auth);
  const { mutate: updateAddress, isLoading: isPutLoading } = putState;
  const isActive = selectedShippingAddress?.id === id;
  const [openModal, setOpenModal] = useState(false);
  const deleteState = useDeleteShippingAddress(auth);
  const { mutateAsync: deleteAddress, isLoading: isDeleteLoading } =
    deleteState;
  const isLoading = isPutLoading || isDeleteLoading;

  const onEdit = () => {
    onPressEdit(shippingAddress);
  };

  const onDelete = () => {
    setOpenModal(true);
  };

  const onDefault = () => {
    const newShippingAddress = trimFields(shippingAddress);
    updateAddress(
      //@ts-ignore
      { ...newShippingAddress, default: true },
      {
        onError: () => {
          dismissErrorNotification();
          createErrorNotification(
            "Se produjo un error al intentar actualizar los datos de la dirección."
          );
        }
      }
    );
  };

  const clickHandler = () => {
    logSelectShippingAddress({
      address: `${mainStreet} ${secondaryStreet} ${number}`,
      alias: nickname,
      addressId: id
    });
    setSelectedShippingAddress(shippingAddress);
    onClick(shippingAddress);
  };

  const onClose = () => setOpenModal(false);

  const deleteHandler = async () => {
    try {
      await deleteAddress(id);
      if (selectedShippingAddress?.id === id) {
        setSelectedShippingAddress(undefined);
        await Storage.remove({ key: SHIPPING_ADDRESS_TOKEN });
      }
      onClose();
    } catch (e) {
      dismissErrorNotification();
      createErrorNotification(
        "Se produjo un error al intentar eliminar los datos de la dirección"
      );
      console.error(
        "DeleteAddressModal: An error occurred while trying to delete address data"
      );
    }
  };

  return (
    <Styles className="ChooseAddress" isActive={isActive} disabled={isLoading}>
      <ListItem
        title={nickname}
        icon={<HeartSVG />}
        description={`${mainStreet}`}
        isLoading={isLoading}
        isDefault={isDefault}
        onClick={clickHandler}
        onEdit={onEdit}
        onDelete={onDelete}
        onDefault={onDefault}
        actions={actions}
        isFixed={dropdownFixed}
      />
      <DeleteAddressModal
        opened={openModal}
        className="DeleteAddressModal"
        icon={<TrashSVG />}
        title="¿Deseas proceder a eliminar?"
        description="Una vez eliminado no podrás recuperar esta información"
        confirmText="Eliminar"
        confirmAction={deleteHandler}
        cancelText="Cancelar"
        cancelAction={onClose}
        onClose={onClose}
      />
    </Styles>
  );
};

ChooseAddress.defaultProps = {};

export default ChooseAddress;
