import React, { useState } from "react";

import Styles from "./NoCoverageNotification.styles";
import { NoCoverageNotificationProps as Props } from "./NoCoverageNotification.types";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import { defaultFunction } from "utils/common.utils";

import LocationSVG from "../../../../../public/assets/images/location.svg";

const { WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;

const NoCoverageNotification: React.FC<Props> = props => {
  const { className, onClose = defaultFunction, opened = true } = props;
  const { onButton } = props;
  const [notificationOpen, setNotificationOpen] = useState(true);
  const t = useI18n();

  const handleCloseNotification = () => {
    setNotificationOpen(false);

    if (onClose) {
      onClose?.();
    }
  };

  if (!WITH_PURCHASE || !notificationOpen) {
    return null;
  }

  return (
    <Styles
      className={`NoCoverageNotification ${className}`}
      opened={opened}
      onClose={onClose}
    >
      <div className="NoCoverageNotification__icon">
        <LocationSVG viewBox="0 0 16 22" />
      </div>
      <p className="NoCoverageNotification__title">
        {t.notificationModal.title}
      </p>
      <p className="NoCoverageNotification__description">
        {t.notificationModal.description}
      </p>
      <Button
        type="BORDER"
        className="NoCoverageNotification__button NoCoverageNotification__button--desktop"
        onClick={e => {
          handleCloseNotification();
          onButton(e);
        }}
      >
        {t.notificationModal.changeLocation}
      </Button>
      <Button
        type="BORDER"
        className="NoCoverageNotification__button NoCoverageNotification__button--mobile"
        onClick={onButton}
      >
        {t.notificationModal.change}
      </Button>
    </Styles>
  );
};

NoCoverageNotification.defaultProps = {
  className: ""
};

export default NoCoverageNotification;
